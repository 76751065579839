import React from "react"
import Layout from "../Components/Layout"
import DocumentUploadStepper from "../Components/DocumentUploadStepper"
import { useTranslation } from "react-i18next"
import DocumentTabs from "../Components/DocumentTabs"
import { useLocation } from "react-router-dom"

const Documents = () => {
    const { t } = useTranslation()
    const [uploadingFile, setUploadingFile] = React.useState(false)
    const title = uploadingFile ? t('Uploading documents file...') : t('Documents')
    const location = useLocation().pathname

    function getTabByLocation(location:string) {
        const locationPieces = location.split('/')
        let tab = 'received'
        if (locationPieces.length > 2) {
            switch (locationPieces[2]) {
                case 'received':
                case 'admin':
                case 'sent':
                    tab = locationPieces[2]
                    break
                default:
                    console.error(`unrecognized tab "${locationPieces[2]}"`)
            }
        }
        return tab
    }

    return (        
        <Layout title={ title } >
            {   !uploadingFile ?
                (<DocumentTabs
                    initialTab={ getTabByLocation( location ) }
                    onUploadFile={ () => setUploadingFile(true) }
                />) :
                (<DocumentUploadStepper 
                    onExit= { () => setUploadingFile(false) }
                />)
            }
        </Layout>
    )
}

export default Documents