import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
//import FeaturesIcon from '@material-ui/icons/PlaylistAddCheck'
//import PricingIcon from '@material-ui/icons/AttachMoney'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../Constants/Routes'
import "./SideMenu.css"
import { useTranslation } from 'react-i18next'

const SideMenu = () => {
    const { t } = useTranslation()

  return (
    <div>
        <NavLink exact 
            to={ROUTES.LANDING} 
            style={{ textDecoration: 'none', color: "inherit" }}
            activeClassName="active"
        >
            <ListItem button>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText  primary={t("Payload")} />
            </ListItem>
        </NavLink>
{/*
        <NavLink 
            to={ROUTES.FEATURES}
            style={{ textDecoration: 'none', color: "inherit" }}
            activeClassName="active"
        >
            <ListItem button>
                <ListItemIcon><FeaturesIcon /></ListItemIcon>
                <ListItemText primary={t("Features")} />
            </ListItem>
        </NavLink>

        <NavLink 
            to={ROUTES.PRICING}
            style={{ textDecoration: 'none', color: "inherit" }}
            activeClassName="active"
        >
            <ListItem button>
                <ListItemIcon><PricingIcon /></ListItemIcon>
                <ListItemText primary={t("Pricing")} />
            </ListItem>
        </NavLink>    
*/}  
    </div>
  )
}

export default SideMenu