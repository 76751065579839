import React from "react"
import { TemplateEntityTypes, TemplateSearch } from "../Shared/Template"
import Template from "../Models/Template"
import { FirebaseContext } from "../Models/Firebase"
import { ListItem, ListItemText, ListItemSecondaryAction, Card, CardContent, Typography, List } from "@material-ui/core"
import EditIcon from '@material-ui/icons/Edit'
import { useTranslation, Trans } from "react-i18next"
import TemplateEdit from "../Dialogs/TemplateEdit"

export interface TemplateEditableListProps {
    entityType: TemplateEntityTypes,
    entityId?: string
}

const TemplateEditableList:React.SFC<TemplateEditableListProps> = ({entityType, entityId}) => {
    const firebase = React.useContext(FirebaseContext)
    const { t } = useTranslation()
    const [data, setData] = React.useState({
        templateOnEdit: null as Template | null,
        templateEditDialogOpened: false
    })

    function buildTemplateList() {
        return Template.getList({
            entityType: entityType,
            entityId: entityId
        })
            .map( templateSearch => (

                <ListItem button onClick={ () => onEditTemplate(templateSearch) } key={templateSearch.type + '-' + templateSearch.language}>
                    <ListItemText
                        primary={ Template.getLabel(templateSearch, t) }
                    />
                    <ListItemSecondaryAction><EditIcon /></ListItemSecondaryAction>
                </ListItem>
            
            ) )
    }

    async function onEditTemplate(templateSearch: TemplateSearch) {
        try {
            if (!firebase) throw new Error('no firebase')
            const template = await Template.retrieve(firebase, templateSearch)
            if (!template) throw new Error('no proper template found!')
            setData({
                ...data,
                templateEditDialogOpened: true,
                templateOnEdit: template
            })
        } catch (e) {
            console.error(e)
        }
    }

    async function onSubmitNewTemplate(subject:string, html:string) {
        try {
            if (!firebase) throw new Error('no firebase')
            if (!data.templateOnEdit) throw new Error('no templateOnEdit to save')
            data.templateOnEdit.html = html
            data.templateOnEdit.subject = subject
            await data.templateOnEdit.save(firebase)
            setData({
                ...data,
                templateEditDialogOpened: false
            })
        } catch (e) {
            console.error(e)
        }
        if (!data.templateOnEdit) {
            console.error('no snapTemplateOnEdit to edit')
            return
        }
    }

    function onCancelNewTemplate() {
        setData({
            ...data,
            templateEditDialogOpened: false
        })
    }

    return (
        <React.Fragment>
            { data.templateEditDialogOpened && data.templateOnEdit ?
                <TemplateEdit
                    submit = { onSubmitNewTemplate }
                    handleCloseWithoutSaving = { onCancelNewTemplate }
                    template = { data.templateOnEdit }
                    title = { t('Edit') + ': ' + data.templateOnEdit.getLabel(t) }
                />
                : null
            }

            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Trans>Email Templates</Trans>
                    </Typography>
                    <List dense>{ buildTemplateList() }</List>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default TemplateEditableList