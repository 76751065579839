import Firebase, { FirebaseApp } from "./Firebase"
import AbsTemplate, { TemplateEntityTypes, IAbsTemplate, TemplateSearch, TemplateRetrieveOptions } from '../Shared/Template'
import _ from "lodash"
import { defaultLanguage } from "../Shared/Constants"

class Template extends AbsTemplate {
    async save(firebase: Firebase) {
        const data = this.getSnapData()
        const templates = firebase.db.collection('templates')

        if (this.id) await templates.doc(this.id).set(data)
            else await templates.add(data)
    }

    static fromSnap(snap:FirebaseApp.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
        const data = snap.data() as IAbsTemplate
        if (!data) throw new Error('no data in snap')

        let template = new Template(data)
        template.id = snap.id

        return template
    }
    
    static async retrieve(firebase: Firebase, search: TemplateSearch, inputOptions?:TemplateRetrieveOptions ): Promise<Template | null> {
        const options:TemplateRetrieveOptions = _.defaults(inputOptions, {
            fallback: true,
            saveFallbackRetrieved: false,
            onFallbackKeepId: false
        })

        let query = firebase.db.collection('templates')
            .where('type', '==', search.type)
            .where('entityType', '==', search.entityType)
            .where('language', '==', search.language)
            
        if (search.entityId) query = query.where('entityId', '==', search.entityId)

        const snap = await query.limit(1).get()
        if (snap.empty) {

            /***
             * FALLBACK STRATEGY:
             * 1. search against SYSTEM entityType and same LANGUAGE
             * 2. search against NOTSYSTEM entityType and default LANGUAGE
             * 3. search against SYSTEM entityType and default LANGUAGE
             */
            if (options.fallback) {
                let fallback = null as Template | null
                let fallback2 = null as Template | null
                let result = null as Template | null

                if (search.entityType !== TemplateEntityTypes.SYSTEM) fallback = await this.retrieve(firebase, {
                    ...search,
                    entityId: undefined,
                    entityType: TemplateEntityTypes.SYSTEM
                })
                
                if (fallback && fallback.language !== search.language && search.language !== defaultLanguage) fallback2 = await this.retrieve(firebase, {
                    ...search,
                    language: defaultLanguage
                }, {
                    fallback: false
                })

                if (fallback2) result = fallback2
                    else result = fallback

                if (result) {
                    result.language = search.language
                    result.entityType = search.entityType
                    result.entityId = search.entityId
                    if (options.saveFallbackRetrieved || !options.onFallbackKeepId) delete result.id

                    if (options.saveFallbackRetrieved)
                        await result.save(firebase)
                }

                return result

            } else return null
        } else return Template.fromSnap(snap.docs[0])
    }
}

export default Template