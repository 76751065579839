export enum OccurrenceEntity {
    USER = 'user',
    DOCUMENT = 'document'
}

export enum OccurrenceAction {
    CREATED = 'created',
    UPDATED = 'updated',
    DELETED = 'deleted',
    OPENED = 'opened',
    NOTIFICATION_ISSUED = 'notification issued',
    NOTIFICATION_DELIVERED = 'notification delivered',
    ACKNOWLEDGED = 'acknowledged',
    PRIVACY_POLICY_ACCEPTED = 'privacy policy accepted',
    ERROR = 'error'
}

export interface IOccurrenceNow {
    id?: string
    timestamp?: string,
    action: OccurrenceAction,
    entity: OccurrenceEntity,
    entityId?: string,
    actor: string,
    target?: string,
    info?: any
}

export interface IOccurrence extends IOccurrenceNow {
    timestamp: string
}

export interface IOccurrenceWithDetailsString extends IOccurrenceNow {
    info: {
        details: string
    }
}

class AbsOccurrence implements IOccurrence {
    id?: string
    timestamp: string
    action: OccurrenceAction
    entity: OccurrenceEntity
    entityId?: string
    actor: string
    target?: string
    info?: any
    
    constructor (data:IOccurrenceNow) {
        this.id = data.id
        this.timestamp = data.timestamp ? data.timestamp : (new Date()).toISOString()
        this.action = data.action
        this.entity = data.entity
        this.entityId = data.entityId
        this.actor = data.actor
        this.target = data.target
        this.info = data.info
    }

    getSnapData() {
        const data = {
            timestamp: this.timestamp,
            action: this.action,
            entity: this.entity,
            actor: this.actor,
        } as IOccurrence

        if (this.entityId) data.entityId = this.entityId
        if (this.target) data.target = this.target
        if (this.info) data.info = this.info

        return data
    }
}

export default AbsOccurrence