import { Card, CardContent, List, ListItem, ListItemText, Typography } from "@material-ui/core"
import red from '@material-ui/core/colors/red'
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import UserDelete from "../Dialogs/UserDelete"
import { UserContext } from "../Models/User"

const UserDisruptiveActions = () => {
    const currentUser = React.useContext(UserContext).currentUser
    const deleteDialogRef = React.useRef()
    const { t } = useTranslation()

    async function onDeleteUser() {
        // @ts-ignore
        deleteDialogRef.current.open(currentUser)
    }

    return (
        <React.Fragment>
            <UserDelete ref={deleteDialogRef} />
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Trans>Disruptive Actions</Trans>
                    </Typography>
                    <List>
                        <ListItem button onClick={ onDeleteUser } >
                            <ListItemText style={{ color: red[500] }} primary={ t('Delete Account') } />
                        </ListItem>    
                    </List>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default UserDisruptiveActions