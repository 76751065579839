import MaterialTable, { Options, Query } from "material-table"
import React from "react"
import { useTranslation } from "react-i18next"
import ErrorSnackbar from "../Components/ErrorSnackbar"
import DocumentAcknowledgement from "../Dialogs/DocumentAcknowledgement"
import Doc from '../Models/Doc'
import ErrorManager, { displayMessage } from "../Models/ErrorManager"
import { FirebaseApp, FirebaseContext } from "../Models/Firebase"
import Procedure from "../Models/Procedure"
import { UserContext, UserStatus } from "../Models/User"
import { DocStatus } from "../Shared/Doc"
import { ProcedureActionType, ProcedureType } from "../Shared/Procedure"
import CommonTable from "./CommonTable"


const DocumentsReceived = () => {
    const { t } = useTranslation()
    const firebase = React.useContext(FirebaseContext)
    const currentUser = React.useContext(UserContext).currentUser
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    let lastSnapshot: FirebaseApp.firestore.QuerySnapshot<FirebaseApp.firestore.DocumentData> | undefined
    let currentPage = 0
    const dialogAcknowledgementRef = React.useRef()

    async function retrieveData(query:Query<Doc>) {
        try {
            if (!firebase) throw new Error('no firebase')
            const result = await firebase.retrieveDataForMaterialTable<Doc>(
                firebase.db.collection('documents').where('deleted', '==', false).where('recipientEmails', 'array-contains', currentUser.email),
                query,
                Doc.fromSnap,
                currentPage,
                lastSnapshot
            )
            lastSnapshot = result.snapshot
            currentPage = result.materialTableData.page
            return result.materialTableData
        } catch(e) {
            // @ts-ignore
            ErrorManager.query(firebase, e, errorSnackbarRef.current.open)
            return CommonTable.dataEmpty<Doc>()
        }
    }

    async function acknowledged(doc:Doc, procedure:Procedure) {
        try {
            if (!firebase) throw new Error('no firebase')
            await procedure.sendAction(firebase, { type: ProcedureActionType.ACKNOWLEDGED })
            //@ts-ignore
            await doc.open(firebase, errorSnackbarRef.current.open as displayMessage)
        } catch (e) {
            //@ts-ignore
            ErrorManager.procedure(firebase, e, procedure.getData(), errorSnackbarRef.current.open)
        }
    }
    
    async function rowAction(doc: Doc) {
        try {
            if (doc.status === DocStatus.WAITING_RESPONSE) {
                if (!firebase) throw new Error('no firebase')
                const procedure = await doc.retrieveProcedure(firebase, currentUser.email)
                if (procedure) {
                    const procedureData = procedure.getData()
                    switch (procedureData.type) {
                        case ProcedureType.ACKNOWLEDGEMENT:
                            //@ts-ignore
                            dialogAcknowledgementRef.current.open(doc, procedure)
                            break
                    }
                }
            }
                //@ts-ignore
                else await doc.open(firebase, errorSnackbarRef.current.open as displayMessage)
        } catch (e) {
            //@ts-ignore
            ErrorManager.noFirebase(e, errorSnackbarRef.current.open)
        }
    }

    if (!firebase || currentUser.getStatus() !== UserStatus.LOGGED) return null

    const tableLocalization = CommonTable.getLocalization(t)

    const tableOptions:Options = {
        showTitle: false,
        thirdSortClick: false,
        search: false,
        pageSize: 15,
        pageSizeOptions: [10, 15, 30]

    }

    return (
        <React.Fragment>
            <ErrorSnackbar ref={ errorSnackbarRef } />

            <DocumentAcknowledgement
                ref={ dialogAcknowledgementRef }
                handleAccepted = { acknowledged }
            />
            
            <MaterialTable
                options = { tableOptions }
                localization = { tableLocalization }
                onRowClick={(event, rowData) => rowData ? rowAction(rowData) : null}
                columns={[
                    { title: t('Date'), field: 'date', defaultSort: 'desc' },
                    { title: t('Title'), field: 'title' }
                ]}
                data={ retrieveData }
            />
        </React.Fragment>

    )
}

export default DocumentsReceived