import React from "react"
import { Card, CardContent, Typography } from "@material-ui/core"
import { Trans } from "react-i18next"
import AlertList from '../Lists/Alerts'

const Alerts = () => {
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    <Trans>Alerts</Trans>
                </Typography>
                <AlertList />
            </CardContent>
        </Card>
    )
}

export default Alerts