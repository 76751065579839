import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { defaultLanguage } from "./Shared/Constants"
const it = require('./Constants/i18n/it.json')
const en = require('./Constants/i18n/en.json')

const resources = {
  it: { translation: it },
  en: { translation: en },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,

    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

  export default i18n