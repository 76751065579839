import React, { forwardRef, useImperativeHandle } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@material-ui/core"
import Doc from "../Models/Doc"
import Procedure from "../Models/Procedure"

export interface DocumentAcknowledgementProps {
    handleAccepted: (doc:Doc, procedure:Procedure) => void,
    handleCloseWithoutSaving?: () => void
}

const DocumentAcknowledgementBase = ( { handleAccepted, handleCloseWithoutSaving }:DocumentAcknowledgementProps, ref: any ) => {
    const { t } = useTranslation()
    const [status, setStatus] = React.useState({
        opened: false,
        doc: null as null | Doc,
        procedure: null as null | Procedure
    })

    function open(doc:Doc, procedure:Procedure) {
        setStatus({
            opened: true,
            doc: doc,
            procedure: procedure
        })
    }

    useImperativeHandle(ref, () => {
        return { open: open }
    })
    
    function onCloseWithoutSaving() {
        setStatus({
            ...status,
            opened: false
        })
        if (handleCloseWithoutSaving) handleCloseWithoutSaving()
    }

    function onAccepted() {
        setStatus({
            ...status,
            opened: false
        })
        handleAccepted(status.doc as Doc, status.procedure as Procedure)
    }

    return (
        <Dialog 
            open={ status.opened }
            onClose={ onCloseWithoutSaving }
            aria-labelledby="group-add-dialog-title"
        >
            <DialogTitle id="group-add-dialog-title">
                <Trans>Document Acknowledgement</Trans>
            </DialogTitle>
            <DialogContent>
                { t('_acknowledgementRequest', status.doc as Doc)}
            </DialogContent>
            <DialogActions>
                <Button onClick={ onCloseWithoutSaving } color="primary">
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={ onAccepted } color="primary">
                    <Trans>Accept</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const DocumentAcknowledgement = forwardRef(DocumentAcknowledgementBase)
export default DocumentAcknowledgement