import AbsOccurrence from "../Shared/Occurrence";
import Firebase from "./Firebase";

class Occurrence extends AbsOccurrence {
    async save(firebase:Firebase) {
        const occurrences = firebase.db.collection('occurrences')
        const data = this.getSnapData()
        if (!this.id) {
            const ref = await occurrences.add(data)
            this.id = ref.id
        } else await occurrences.doc(this.id).set(data)
    }
}

export default Occurrence