export const storiesTitlePrefix = '[%PayloadStory%]'
export const storiesRecipientEmail = 'fairsayan@gmail.com'
export const storiesFakeLoginEmail = 'payload@wink.by'

export const platformSenderEmail = 'noreply.payload@wink.by'
export const platformAdministrator = 'domenico.pontari@gmail.com'
export const platformLanguages = ['it']
export const defaultLanguage = 'it'
export const alertsForProductionEnvOnly = true
export const backupBucketPath = 'gs://payload-backup'

export enum EnvName {
    DEVEL = 'development', // pdfservice running with grunt 
    LOCAL = 'local', // perservice runnning locally inside a container
    PRODUCTION = 'production'
}

export interface EnvData {
    baseUrl: string
    pdfServerBaseUrl: string
    name: EnvName
    nodeEnv: string | undefined,
    functionsBaseUrl: string
}

export class Env {
    private static data:EnvData
    private static inizialized = false

    private constructor() {}

    private static getData(envName:EnvName) {
        let pdfServerBaseUrl:string
        let baseUrl:string
        let functionsBaseUrl:string
    
        switch(envName) {
            case EnvName.LOCAL:
                pdfServerBaseUrl = 'http://localhost:80'
                baseUrl = 'http://localhost:3000'
                functionsBaseUrl = 'http://localhost:5001/payload-1aa6f/europe-west1'
                break
            case EnvName.DEVEL:
                pdfServerBaseUrl = 'http://localhost:8081'
                baseUrl = 'http://localhost:3000'
                functionsBaseUrl = 'http://localhost:5001/payload-1aa6f/europe-west1'
                break
            case EnvName.PRODUCTION:
                pdfServerBaseUrl = 'https://pdfservice-wh2o2d6e6a-ew.a.run.app'
                baseUrl = 'https://payload.wink.by'
                functionsBaseUrl = 'https://europe-west1-payload-1aa6f.cloudfunctions.net'
                break
            default:
                throw new Error(`unrecognized envName "${envName}"`)
        }
    
        const result:EnvData = {
            name: envName,
            nodeEnv: process.env.NODE_ENV,
            pdfServerBaseUrl: pdfServerBaseUrl,
            baseUrl: baseUrl,
            functionsBaseUrl: functionsBaseUrl
        }
    
        return result    
    }

    static set(envName?:EnvName) {
        if (!envName)
            envName = process.env.NODE_ENV === 'production' ? EnvName.PRODUCTION : EnvName.DEVEL
        this.inizialized = true
        this.data = this.getData(envName)
        return this.data
    }

    static get(envName?:EnvName) {
        if (envName) return this.getData(envName)
        if (this.inizialized) return this.data
            else return this.set()
    }
}