import { DataStatus, getIntervalsBasedOnToken, italianMonths } from "./Misc"
import { DocType, IDoc } from "./Doc"
import { getFiscalcode, GetFiscalcodeResultState } from "./fiscalCode"
import _ from "lodash"

export interface FileDocsSection {
    dataDoc: IDoc
    startPage: number
    endPage: number
}

const paycheckSignature = "Autorizzazione INAIL N"
const cuSignature = "6-ter e 6-quater"

class FileDocs {
    pages = [] as string[]
    protected status = DataStatus.LOADING
    sections = [] as FileDocsSection[]
    sender?:string

    constructor(pages?:string[], sender?:string) {
        if (pages) {
            this.pages = pages
            if (sender) this.elaborate(sender)
        }
    }

    getStatus() { return this.status }

    protected isCU() {
        for (const page of this.pages)
            if (page.indexOf(cuSignature) !== -1) return true
        return false
    }

    protected isPaycheck() {
        for (const page of this.pages)
            if (page.indexOf(paycheckSignature) !== -1) return true
        return false
    }

    protected getType() {
        let type = DocType.OTHER
        if (this.isCU()) type = DocType.UNIQUE_CERTIFICATION
            else if (this.isPaycheck()) type = DocType.PAYCHECK
        
        return type
    }

    protected elaborateOther() {
        const dataDoc = { sender: this.sender, type: DocType.OTHER, numOfPages: this.pages.length } as IDoc

        this.sections = [{
            dataDoc: dataDoc,
            startPage: 1,
            endPage: this.pages.length
        }]
    }

    protected elaborateCU() {
        function getFiscalcodeInPages(textPages: string[]) {
            if (textPages.length === 0) return null
            let possibleFiscalcodes = getFiscalcode(textPages[0]).values
            for (let page = 1; page < textPages.length; ++page) {
                if (possibleFiscalcodes.length === 0) break
                const otherValues = getFiscalcode(textPages[page]).values
                possibleFiscalcodes = _.intersection(possibleFiscalcodes, otherValues)
            }
            if (possibleFiscalcodes.length === 1) return possibleFiscalcodes[0]
            return null
        }

        function getYearInPages(textPages: string[]) {
            if (textPages.length === 0) return null
            const fullText = textPages.join().replace(/ /, '')
            const match = fullText.match(/UNICA(20[\d]{2})/m)
            if (match) return parseInt(match[1])
    
            return null
        }
    
        const intervals = getIntervalsBasedOnToken(this.pages, cuSignature)
        this.sections = intervals.map( interval => {
            const currentPages = this.pages.slice(interval.start, interval.end + 1)
            const fiscalcode = getFiscalcodeInPages(currentPages)
            const year = getYearInPages(currentPages)

            if (!fiscalcode) throw new Error('unable to detect fiscalcode in pages')
            if (!year) throw new Error('unable to detect year in pages')
            const cuData = {
                year: year,
                sender: this.sender,
                fiscalcode: fiscalcode,
                type: DocType.UNIQUE_CERTIFICATION
            } as IDoc
            cuData.date = `${year}-03-31`

            return {
                dataDoc: cuData,
                startPage: interval.start + 1,
                endPage: interval.end + 1
            }
        })
    }

    protected elaboratePaycheck() {
        interface PaycheckMonthAndYear {
            month: number,
            year: number
        }

        const getMonthAndYear = (page: string): PaycheckMonthAndYear | null => {
            for (let j = 0; j < italianMonths.length; j++) {
                const month = italianMonths[j]
                const reg = new RegExp(month + ' (20[\\d]{2})', 'im')
                const match = page.match(reg)
    
                if (match) return {
                    month: j + 1,
                    year: parseInt(match[1])
                }
            }
            return null
        }

        const paycheckGetFiscalcode = (page:string, pageNum:number) => {
            const fiscalcode = getFiscalcode(page, {
                companies: false,
                tryWithoutSpaces: false
            })
            if (!fiscalcode) throw new Error(`unable to detect fiscalcode at page ${pageNum}`)
            if (fiscalcode.state !== GetFiscalcodeResultState.UNIQUE) throw new Error(`expected only one fiscalcode at page ${pageNum}`)
            return fiscalcode.value as string
        }

        const createSection = (currPage:number):FileDocsSection => {
            if (!prevMonthAndYear) throw new Error('unable to detect month and year')
            const paycheck = {
                sender: this.sender,
                month: prevMonthAndYear.month,
                year: prevMonthAndYear.year,
                fiscalcode: prevFiscalcode,
                type: DocType.PAYCHECK
            } as IDoc
            const startPage = initPage
            const endPage = currPage - 1
            return {
                dataDoc: paycheck,
                startPage: startPage + 1,
                endPage: endPage + 1
            }
        }

        let prevFiscalcode = paycheckGetFiscalcode(this.pages[0], 1)
        let initPage = 0
        let prevMonthAndYear = getMonthAndYear(this.pages[0])
        if (!prevMonthAndYear) throw new Error('unable to detect month and year at page 1')
        const result = [] as FileDocsSection[]

        for (let i = 1; i < this.pages.length; i++) {
            const page = this.pages[i]
            const currFiscalcode = paycheckGetFiscalcode(page, i + 1)
            const monthAndYear = getMonthAndYear(page)
            if (!monthAndYear) throw new Error(`unable to detect month and year at page ${i + 1}`)
            
            if ( monthAndYear.month !== prevMonthAndYear.month || 
                monthAndYear.year !== prevMonthAndYear.year || 
                currFiscalcode !== prevFiscalcode) {
                result.push( createSection(i) )
                initPage = i
                prevMonthAndYear = monthAndYear
                prevFiscalcode = currFiscalcode
            }
        }

        result.push( createSection(this.pages.length) )
        this.sections = result
    }

    elaborate(sender:string) {
        let result = true
        this.sender = sender
        if (this.pages.length === 0) {
            this.status = DataStatus.EMPTY
            this.sections = []
            return result
        }
        const type = this.getType()
        try {
            switch (type) {
                case DocType.PAYCHECK:
                    this.elaboratePaycheck()
                    break
                case DocType.UNIQUE_CERTIFICATION:
                    this.elaborateCU()
                    break
                case DocType.OTHER:
                    this.elaborateOther()
            }
        } catch (e) {
            if (type === DocType.OTHER) throw e
                else console.error(e)
            this.elaborateOther()
            result = false
        }

        this.status = DataStatus.READY
        return result
    }
}

export default FileDocs