import React, { lazy } from "react"
import { Tabs, Tab, Paper } from "@material-ui/core"
import TabPanel from "./TabPanel"
import { useTranslation } from "react-i18next"
import DocumentsReceived from "../Tables/DocumentsReceived"
import DocumentsSent from "../Tables/DocumentsSent"
import { UserContext, UserStatus } from "../Models/User"

const DocumentsAdmin = lazy(() => import('../Tables/DocumentsAdmin'))

export interface DocumentTabsProps {
    onUploadFile:(uploading:boolean) => void
    initialTab?:string
}

const tabIndexes = {
    'received': 0,
    'sent': 1,
    'admin': 2
}

const DocumentTabs:React.SFC<DocumentTabsProps> = ({ onUploadFile, initialTab }) => {
    const currentUser = React.useContext(UserContext).currentUser
    // @ts-ignore
    const [value, setValue] = React.useState(initialTab ? tabIndexes[initialTab] : 0)
    const { t } = useTranslation()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    if (currentUser.getStatus() !== UserStatus.LOGGED) return null
    return (
        <div>

            <Paper square>
                <Tabs 
                    variant="fullWidth"
                    value={value}
                    onChange={ handleChange }
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="document tabs"
                >
                    <Tab 
                        label={ t('Received') }
                        id="received-documents-tab"
                        aria-controls="received-documents-tabpanel" 
                    />
                    <Tab 
                        label={ t('Sent') }
                        id="sent-documents-tab"
                        aria-controls="sent-documents-tabpanel"
                    />
                    { currentUser.isAdmin ? (
                        <Tab 
                            label={ t('Admin') }
                            id="admin-documents-tab"
                            aria-controls="admin-documents-tabpanel"
                        />
                    ) : null}
                </Tabs>
            </Paper>

            <TabPanel 
                value={ value }
                index={0}
                id="received-documents-tabpanel"
                ariaLabelledby="received-documents-tab"
            >
                <DocumentsReceived />
            </TabPanel>
            
            <TabPanel
                value={ value }
                index={1}
                id="sent-documents-tabpanel"
                ariaLabelledby="sent-documents-tab"
            >
                <DocumentsSent 
                    onUploadFile = { onUploadFile }
                />
            </TabPanel>
        
            <TabPanel
                value={ value }
                index={2}
                id="admin-documents-tabpanel"
                ariaLabelledby="admin-documents-tab"
            >
                <DocumentsAdmin />
            </TabPanel>
        
        </div>
    )

}

export default DocumentTabs