import React from "react"
import { Stepper, Step, StepLabel, Grid } from "@material-ui/core"
import { Trans } from "react-i18next"
import DocumentUploadFile from "../Cards/DocumentUploadFile" // @stubable
import { FirebaseApp, FirebaseContext } from "../Models/Firebase"
import ReviewUploadedFile from "../Components/ReviewUploadedFile"
import FileDocs from "../Shared/FileDocs"
import ErrorSnackbar from "./ErrorSnackbar"
import ErrorManager from "../Models/ErrorManager"

const DocumentUploadStepper:React.SFC<{onExit:() => void}> = ({ onExit }) => {
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const firebase = React.useContext(FirebaseContext)
    const [status, setStatus] = React.useState({
        activeStep: 0,
        fileDocs: new FileDocs(),
        tmpFileRef: null as FirebaseApp.storage.Reference | null
    })

    async function onUploaded(tmpFileRef: FirebaseApp.storage.Reference) {
        try {
            if (!firebase) throw new Error('no firebase')
            const currentFirebaseUser = firebase.app.auth().currentUser
            if (!currentFirebaseUser) throw new Error('no firebase user')
            const pdf2txt = firebase.app.functions('europe-west1').httpsCallable('pdf2txt')
            const result = await pdf2txt({GCPSourcePath: tmpFileRef.fullPath})
            status.fileDocs.pages = result.data
            const elaborationResult = status.fileDocs.elaborate(currentFirebaseUser.uid)
            if (!elaborationResult) await ErrorManager.fileElaboration(firebase, tmpFileRef.fullPath)
            setStatus({
                activeStep: 1,
                fileDocs: status.fileDocs,
                tmpFileRef: tmpFileRef
            })
        } catch(e) {
            console.error(e)
            // @ts-ignore
            await ErrorManager.file(firebase, e, tmpFileRef.fullPath, errorSnackbarRef!.current!.open)
        }
    }

    const steps = [
        (
            <DocumentUploadFile
                onUploaded = { onUploaded }
                onCancel = { onExit }
            />
        ),
        (            
            <ReviewUploadedFile
                fileDocsGetter = { () => status.fileDocs }
                tmpFilePath = { status.tmpFileRef?.fullPath as string }
                onCompleted = { onExit }
                onCancel = { onExit }
            />
        )
    ]

    return (
        <React.Fragment>
            <ErrorSnackbar ref={ errorSnackbarRef } />
            <Grid container spacing={2} alignContent="center">
                <Grid item xs={12}>
                    <Stepper activeStep={ status.activeStep }>
                        <Step key="1">
                            <StepLabel><Trans>upload documents file</Trans></StepLabel>
                        </Step>
                        <Step key="2">
                            <StepLabel><Trans>select recipients</Trans></StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    { steps[status.activeStep] }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default DocumentUploadStepper