import moment from 'moment'
import { emailError, padZeros } from "./Misc"
import { FiscalRegex, FiscalcodeType } from "./fiscalCode"
import _ from "lodash"

export enum DocType {
    PAYCHECK = 'paycheck',
    UNIQUE_CERTIFICATION = 'unique certification',
    OTHER = 'other'
}

export enum DocStatus {
    READY = 'ready',
    CREATION = 'creation',
    WAITING_RESPONSE = 'waiting',
    ERROR = 'error'
}

export interface IDoc {
    id?: string
    type?: DocType
    sender: string
    recipientEmails?: string[]
    title?: string
    group?: string
    date?: string
    filename?: string
    deleted?: boolean
    status?: DocStatus

    fiscalcode?: string
    year?: number
    month?: number
}

class AbsDoc implements IDoc {
    id?: string
    type = DocType.OTHER
    sender: string
    recipientEmails = [] as string[]
    title: string = ''
    group?: string
    date?: string
    filename?: string
    deleted = false
    status = DocStatus.READY

    fiscalcode?: string
    year?: number
    month?: number

    constructor(data:IDoc) {
        this.id = data.id
        if (data.type) this.type = data.type
        this.sender = data.sender
        if (data.recipientEmails) this.recipientEmails = data.recipientEmails
        if (data.title) this.title = data.title
        this.group = data.group
        this.date = data.date
        this.filename = data.filename
        if (data.deleted !== undefined) this.deleted = data.deleted
        if (data.status) this.status = data.status

        this.fiscalcode = data.fiscalcode
        this.year = data.year
        this.month = data.month
    }

    getData() {
        return {
            id: this.id,
            type: this.type,
            sender: this.sender,
            recipientEmails: this.recipientEmails,
            title: this.title,
            group: this.group,
            date: this.date,
            filename: this.filename,
            deleted: this.deleted,
            status: this.status,

            fiscalcode: this.fiscalcode,
            year: this.year,
            month: this.month
        } as IDoc
    }

    getSnapData() {
        const errors = this.getErrors()
        if (errors.length > 0) throw new Error(errors.join("\n"))
        
        const data:IDoc = {
            type: this.type,
            sender: this.sender,
            recipientEmails: this.recipientEmails,
            title: this.title,            
            deleted: this.deleted,
            status: this.status
        }
        if (this.group) data.group = this.group
        if (this.date) data.date = this.date
        if (this.filename) data.filename = this.filename

        switch (this.type) {
            case DocType.PAYCHECK:
                data.fiscalcode = this.fiscalcode
                data.year = this.year
                data.month = this.month
                break
            case DocType.UNIQUE_CERTIFICATION:
                data.fiscalcode = this.fiscalcode
                data.year = this.year
        }

        return data
    }

    getYearMonthString() {
        const monthStr = padZeros(this.month as number, 2)
        return `${this.year}-${monthStr}`
    }

    getTitle(t: Function) {
        let title:string = t('Document')
        switch (this.type) {
            case DocType.PAYCHECK: title = t('Paycheck') + ' ' + this.getYearMonthString(); break
            case DocType.UNIQUE_CERTIFICATION: title = t('Unique Certification {{year}}', {year: this.year}); break
        }
        return title
    }

    getPdfPath() {
        if (this.filename) return `pdf/${this.filename}.pdf`
        if (!this.id) throw new Error(`no id => no pdf url`)
        return `pdf/${this.id}.pdf`
    }

    getJsonPath() {
        if (this.filename) return `json/${this.filename}.json`
        if (!this.id) throw new Error(`no id => no json url`)
        return `json/${this.id}.json`
    }

    getFiscalcodeType() {
        if (!this.fiscalcode) throw new Error('no fiscalcode provided')
        if (this.fiscalcode.match(FiscalRegex.companies)) return FiscalcodeType.COMPANIES
        if (this.fiscalcode.match(FiscalRegex.people)) return FiscalcodeType.PEOPLE
        return FiscalcodeType.INVALID
    }

    getSecurityRecipientsString() {
        return this.recipientEmails.length > 0 ? this.recipientEmails.map( email => AbsDoc.getRecipientEmailSecured(email) ).join(' ') : ''
    }

    getFiscalcodeError(t?:Function):string {
        const identity = (err:string) => err
        if (!t) t = identity

        if (!this.fiscalcode) return t('fiscalcode is required')
        if (this.getFiscalcodeType() === FiscalcodeType.INVALID) return t('invalid fiscalcode')
        return ''
    }

    getYearError(t?:Function):string {
        const identity = (err:string) => err
        if (!t) t = identity

        if (this.year) {
            if (this.year < 1900 || this.year > 2100) return t('invalid year')
        } else {
            if (this.type != DocType.OTHER) return t('year is required')
        }
        
        return ''
    }

    getRecipientErrors(t?:Function) {
        return this.recipientEmails.map( email => emailError(email, true, t) )
            .filter( error => !!error )
    }

    getErrors() {
        const errors = this.getRecipientErrors()
        const yearError = this.getYearError()
        if (yearError) errors.push(yearError)
        if (!this.title) errors.push('title is required')

        if (this.date || this.date === '') {
            const date = moment(this.date)
            if (!date.isValid()) errors.push(`"${this.date}" is not a valid date`)
        }

        switch (this.type) {
            case DocType.PAYCHECK:
                const fiscalcodeError = this.getFiscalcodeError()
                if (fiscalcodeError) errors.push(fiscalcodeError)
                if (!this.year) errors.push(`year is required for "${this.type}" document`)
                if (!this.month) errors.push(`month is required for "${this.type}" document`)
                break
            case DocType.UNIQUE_CERTIFICATION:
                const fiscalcodeError2 = this.getFiscalcodeError()
                if (fiscalcodeError2) errors.push(fiscalcodeError2)
                if (!this.year) errors.push(`year is required for "${this.type}" document`)
                if (this.month) errors.push(`month is not allowed for "${this.type}" document`)
                break
            default:
                const notAllowed = ['fiscalcode', 'month', 'year']
                for (const attr of notAllowed)
                    //@ts-ignore
                    if (this[attr]) errors.push(`${this[attr]} is not allowed for "${this.type}" document`)
        }

        return errors
    }

    static sameRecipients(doc1:AbsDoc, doc2:AbsDoc) {
        const recipients1 = doc1.recipientEmails.sort()
        const recipients2 = doc2.recipientEmails.sort()
        return _.isEqual(recipients1, recipients2)
    }

    static getRecipientEmailSecured(email: string) {
        return `!${email}>`
    }
}

export default AbsDoc