import React from "react"
import RecipientRow, { RecipientRowData } from "./RecipientRow"
import { Typography, Button } from "@material-ui/core"
import { Trans } from "react-i18next"
import { ProcedureType } from "../Shared/Procedure"

export interface RecipientsEditProps {
    recipients: RecipientRowData[]
    onEmailChanged: (recipientPos:number, email:string) => void,
    onProcedureTypeChanged: (recipientPos:number, procedureType?:ProcedureType) => void,
    onSaveContactChanged: (recipientPos:number, saveContact:boolean) => void,
    maxElements?: number
    variableNumOfElements?: boolean
}

const RecipientsEdit = ({recipients, onEmailChanged, onProcedureTypeChanged, onSaveContactChanged, maxElements = 10, variableNumOfElements=true}: RecipientsEditProps) => {
    const rows = recipients.map( (recipientData, pos) => (
        <RecipientRow
            key = { pos }
            input = { recipientData }
            onEmailChanged = { (email) => onEmailChanged(pos, email) }
            onProcedureTypeChanged = { (procedureType) => onProcedureTypeChanged(pos, procedureType) }
            onSaveContactChanged = { (saveContact) => onSaveContactChanged(pos, saveContact) }
            showSaveContactControl
        />
    ))

    function addButton() {
        if (!variableNumOfElements) return null
        return (
            <Button color="primary" size="small" disabled={ recipients.length >= maxElements ? true : false }><Trans>Add</Trans></Button>
        )
    }

    return (
        <React.Fragment>
            <Typography style={{marginTop: '1em', fontSize:'12pt', fontWeight: 'bold'}} variant="h6">
                { !variableNumOfElements && maxElements === 1 ? (<Trans>Recipient</Trans>) : <Trans>Recipients</Trans>}
                { addButton() }
            </Typography>
            { rows }
        </React.Fragment>
    )

}

export default RecipientsEdit