import { AbsProcedureBase, IProcedureEntry, ProcedureAction } from "../Shared/Procedure"
import Firebase from "./Firebase"


class Procedure extends AbsProcedureBase {
    async save(firebase:Firebase, autostart = true) {
        const data:any = this.getSnapData()
        if (!autostart) data.autostart = false
        const collection = firebase.db.collection('procedures')

        if (!this.id) {
            const ref = await collection.add(data)
            this.id = ref.id
        } else await collection.doc(this.id).set(data)
    }

    async sendAction(firebase:Firebase, action:ProcedureAction) {
        const procedureAction = firebase.app.functions('europe-west1').httpsCallable('procedureAction')
        await procedureAction({
            id: this.id,
            action: action
        })
    }

    static fromSnap(snap:any) {
        const data = snap.data() as IProcedureEntry
        data.id = snap.id
        return new Procedure(data)
    }
}

export default Procedure