import React from "react"
import { Button, Card, CardContent, Typography, CardActions, Grid, makeStyles, Theme, createStyles, Dialog, Backdrop, CircularProgress } from "@material-ui/core"
import { Trans, useTranslation } from "react-i18next"
import { FirebaseContext, FirebaseApp } from "../Models/Firebase"
import { DropzoneArea } from 'material-ui-dropzone'
import './DocumentUploadFile.css'
import ErrorSnackbar from "../Components/ErrorSnackbar"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupSelector: {
        margin: "auto",
        textAlign: "center"
    },
    dropzone: {
        fontFamily: theme.typography.fontFamily
    }
  }),
)

export interface DocumentUploadFileProps {
    onCancel: () => void,
    onUploaded: (fileRef: FirebaseApp.storage.Reference, groupId?:string) => void,
    filename?: string
}

const DocumentUploadFile:React.SFC<DocumentUploadFileProps> = ({ onCancel, onUploaded, filename }) => {
    const { t } = useTranslation()
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const firebase = React.useContext(FirebaseContext)
    const [status, setStatus] = React.useState({
        files: [] as File[],
        uploadingProgress: 0,
        isUploading: false, 
        groupId: undefined as string | undefined
    })
    const classes = useStyles()
    if (!firebase) return null

    function handleChanges(files:File[]) {
        setStatus({
            ...status,
            files: files
        })
    }

    function onUpload() {
        try {
            if (!firebase) throw new Error('no firebase')
            const path = filename ? `tmp/${filename}` : 'tmp/' + Math.random().toString(36).substr(2, 9) + '.pdf'
            const remoteFileRef = firebase.storageRef.child(path)

            setStatus({
                ...status,
                isUploading: true
            })
            const uploadTask = remoteFileRef.put(status.files[0])
            
            uploadTask.on('state_changed', function(snapshot){
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setStatus({
                    ...status,
                    uploadingProgress: progress
                })
            }, (error) => {
                console.error(error)
                // @ts-ignore
                errorSnackbarRef.current.open(error.message)
                setStatus({
                    ...status,
                    isUploading: false,
                    uploadingProgress: 0
                })
            }, () => {
                onUploaded(uploadTask.snapshot.ref, status.groupId)
            })

        } catch (e) {
            console.error(e)
        }
    }

    return (
        <React.Fragment>
            <Dialog open={ status.isUploading }>
                <Backdrop open={ status.isUploading }>
                    <CircularProgress color="secondary" />
                </Backdrop>
            </Dialog>
            <ErrorSnackbar ref={ errorSnackbarRef } />
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Trans>Upload document file</Trans>
                    </Typography>
                        <Grid container spacing={2} alignContent="center">
                            <Grid item xs={12} className={ classes.dropzone } >
                                <DropzoneArea 
                                    dropzoneClass = "dropzone"
                                    dropzoneText = { t('Drag and drop a file here or click')  }
                                    acceptedFiles = { ["application/pdf"] }
                                    filesLimit= { 1 }
                                    maxFileSize = { 5000000 }
                                    useChipsForPreview={ true }
                                    onChange={ handleChanges }
                                    showAlerts={ false }
                                />
                            </Grid>
                        </Grid>
                </CardContent>
                <CardActions>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                            <Button variant="contained" onClick={ onCancel }>
                                <Trans>Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={ onUpload } color="primary" disabled={ status.files.length === 0 }>
                                <Trans>Upload</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </React.Fragment>
    )
}

export default DocumentUploadFile