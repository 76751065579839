import { Card, CardContent, List, ListItem, ListItemText, Typography, Backdrop, CircularProgress, Dialog } from "@material-ui/core"
import axios from 'axios'
import React, { useContext } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FirebaseContext } from "../Models/Firebase"
import { Env } from "../Shared/Constants"
import ErrorSnackbar from "../Components/ErrorSnackbar"

const BackofficeActions = () => {
    const { t } = useTranslation()
    const firebase = useContext(FirebaseContext)
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const [loading, setLoading] = React.useState(false)

    async function actions(action:string) {
        try {
            if (!firebase) throw new Error('no firebase')
            setLoading(true)
            const result = await axios.get( Env.get().functionsBaseUrl + `/${action}` )
            const text = result.data
            if (errorSnackbarRef.current)
                //@ts-ignore
                errorSnackbarRef.current.open(text !== 'ok' ? 'check logs' : 'ok')
        } catch (e) {
            console.error(e)
            if (errorSnackbarRef.current)
                //@ts-ignore
                errorSnackbarRef.current.open(e.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Dialog open={ loading }>
                <Backdrop open={ loading }>
                    <CircularProgress color="secondary" />
                </Backdrop>
            </Dialog>
            <ErrorSnackbar ref={errorSnackbarRef} />
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        <Trans>Backoffice Actions</Trans>
                    </Typography>
                    <List>
                        <ListItem button onClick={ () => actions('cron') }>
                            <ListItemText primary={ t('Cron') } />
                        </ListItem>
                        <ListItem button onClick={ () => actions('backup') }>
                            <ListItemText primary={ t('Backup') } />
                        </ListItem>
                        <ListItem button onClick={ () => actions('integrityCheck') }>
                            <ListItemText primary={ t('Integrity Check') } />
                        </ListItem>
                        <ListItem button onClick={ () => actions('resetStories') }>
                            <ListItemText primary={ t('Reset Stories') } />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default BackofficeActions