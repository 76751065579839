import React from 'react'
import { PersonalInfo } from '../Shared/User'
import PersonalInfoRow from './PersonalInfoRow'

const MultiPersonalInfoRow:React.SFC<{ users:PersonalInfo[] }> = ( { users }) => {
    const list = users.map(
        (user, pos) => (<PersonalInfoRow key={pos} user={user} short />)
    )

    return ( <React.Fragment>{ list }</React.Fragment> )
}

export default MultiPersonalInfoRow