import { emailError } from "./Misc"
import { fiscalcodeError } from './fiscalCode'

export interface IContact {
    id?: string
    name?: string
    email: string
    fiscalcode?: string
    owner: string
    group?: string
}

class AbsContact implements IContact {
    id?: string
    name?: string
    email: string
    fiscalcode?: string
    owner: string
    group?: string

    constructor(data: IContact) {
        this.id = data.id
        this.name = data.name
        this.email = data.email
        this.fiscalcode = data.fiscalcode
        this.owner = data.owner
        this.group = data.group
    }

    getEmailError(t?:Function) {  return emailError(this.email, true, t) }
    getFiscalcodeError(t?:Function) {  return fiscalcodeError(this.fiscalcode, {t: t}) }

    getErrors() {
        const errors = [] as string[]
        const emailErrorStr = emailError(this.email, true)
        if (emailErrorStr) errors.push(emailErrorStr)
        if (!this.owner) errors.push('owner is required')

        return errors
    }

    getData() {
        const data = this.getSnapData()
        data.id = this.id
        return data as IContact
    }

    getSnapData() {
        const data:any = {
            email: this.email,
            owner: this.owner
        }

        if (this.group) data.group = this.group
        if (this.name) data.name = this.name
        if (this.fiscalcode) data.fiscalcode = this.fiscalcode.toUpperCase()

        return data
    }
}

export default AbsContact