import React from "react"
import Layout from '../Components/Layout'

const Pricing = () => {
    return (
        <Layout>
            <div>pricing</div>
        </Layout>
        
    )
}

export default Pricing