import { AlertEntityType, AlertType, IAlertDefault } from "./Alert"
import { defaultLanguage, Env } from "./Constants"
import { IContact } from "./Contact"
import { IDoc, DocStatus } from "./Doc"
import { IEmailTemplate } from "./Email"
import { TemplateEntityTypes } from "./Template"
import { TemplateData_documentAcknowledgementRequestEmail } from "./templateTypes/documentAcknowledgementRequestEmail"
import { TemplateData_documentUploadedEmail } from "./templateTypes/documentUploadedNotificationEmail"
import { IUser, IUserDb } from "./User"

export enum ProcedureType {
    ACKNOWLEDGEMENT = 'acknowledgement',
    COUNTERSIGN = 'countersign',
    NOTIFICATION_DOCUMENT_UPLOADED = 'document uploaded',
    FILE = 'file'
}

export enum ProcedureStatus {
    WAITING_DOCUMENT = 'waiting document',
    CREATED = 'created',
    ISSUED_NOTIFICATION = 'issued notification',
    ACTION_REQUIRED = 'action required',
    ERROR = 'error',
    ENDED = 'ended'
}

export enum ProcedureActionType { 
    NOTIFICATION_SENT = 'notification sent', 
    CHANGE_RECIPIENT = 'change recipient', 
    CREATED = 'created', 
    ACKNOWLEDGED = 'acknowledged', 
    UPDATED = 'updated', 
    COPY = 'copy', 
    SPLIT = 'split'
}

export interface ProcedureAction {
    type: ProcedureActionType,
    data?: any
}

export interface IProcedureEntry {
    id?: string
    issuer: string
    handler: string
    type: ProcedureType
    status?: ProcedureStatus
    recipientEmail?: string
    output?: string
}

export interface ProcedureChangeRecipientOptions {
    newEmail: string
    restartProcedure: boolean
}

export interface ProcedureSplitFileDataEl {
    from: number
    to: number
    documentId: string
}

export class AbsProcedureBase {
    protected id?:string
    protected issuer: string
    protected handler: string
    protected type: ProcedureType
    protected status = ProcedureStatus.CREATED
    protected recipientEmail?: string
    protected output?: string

    constructor (data:IProcedureEntry) {
        this.issuer = data.issuer
        this.handler = data.handler
        this.type = data.type
        this.recipientEmail = data.recipientEmail
        this.output = data.output
        
        if (data.status) this.status = data.status
    }

    getSnapData() {
        const data:IProcedureEntry = {
            issuer: this.issuer,
            handler: this.handler,
            type: this.type,
            status: this.status
        }
        
        if (this.recipientEmail) data.recipientEmail = this.recipientEmail
        if (this.output) data.output = this.output
        return data
    }

    getData() {
        const data = this.getSnapData()
        data.id = this.id
        return data
    }
}

abstract class AbsProcedure extends AbsProcedureBase {
    protected abstract async onError(e:any):Promise<void>
    protected abstract async getDocumentData(id:string):Promise<IDoc>
    protected abstract async getContactDataByEmail(email:string, owner:string):Promise<IContact | null>
    protected abstract async getUserData(id:string):Promise<IUser>
    protected abstract async getUserDbDataByEmail(email:string):Promise<IUserDb | null>
    protected abstract async sendEmail(data:IEmailTemplate, occurrenceInfo:{ document: {title: string}, senderEmail: string}):Promise<void>
    protected abstract async notificationSent():Promise<void>
    protected abstract async requestFulfilled():Promise<void>
    protected abstract async copyFile(toPath:string):Promise<void>
    protected abstract async splitFile(data:ProcedureSplitFileDataEl[]):Promise<void>
    protected abstract async ended():Promise<void>
    abstract async save():Promise<void>

    protected getAlertDataOnError(e:any):IAlertDefault {
        let alertEntityType: AlertEntityType

        switch (this.type) {
            default:
                alertEntityType = AlertEntityType.PLATFORM
        }

        return {
            message: e.message,
            stack: e.stack,
            type: AlertType.PROCEDURE,
            user: this.issuer,
            entityType: alertEntityType
        }
    }

    protected async sendDocumentNotification() {
        const docData = await this.getDocumentData(this.handler)
        if (docData.status === DocStatus.CREATION) {
            // waiting for creation
            this.status = ProcedureStatus.WAITING_DOCUMENT
            await this.save()
            return 
        }
        if (!this.recipientEmail) throw new Error('no recipient') // t('no recipient')
        if (!this.id) throw new Error('no id')
        const recipientUserData = await this.getUserDbDataByEmail( this.recipientEmail )
        const recipientContactData = await this.getContactDataByEmail( this.recipientEmail, this.issuer )
        const language = recipientUserData && recipientUserData.language ? recipientUserData.language : defaultLanguage
        const sender = await this.getUserData( this.issuer )
        let templateData:any
        let templateType:string

        switch (this.type) {
            case ProcedureType.NOTIFICATION_DOCUMENT_UPLOADED:
                const notificationDocumentUploadedData:TemplateData_documentUploadedEmail = {
                    recipient: {
                        displayName: recipientContactData && recipientContactData.name ? recipientContactData.name : ''
                    },
                    document: {
                        title: docData.title ? docData.title : '',
                    },
                    sender: {
                        displayName: sender.displayName ? sender.displayName : ''
                    }
                }
                templateData = notificationDocumentUploadedData
                templateType = 'documentUploadedEmail'
                break
            case ProcedureType.ACKNOWLEDGEMENT:
                const acknowledgementTemplateData:TemplateData_documentAcknowledgementRequestEmail = {
                    recipient: {
                        displayName: recipientContactData && recipientContactData.name ? recipientContactData.name : ''
                    },
                    document: {
                        title: docData.title ? docData.title : '',
                        link: `${Env.get().baseUrl}/document/${docData.id}`
                    },
                    sender: {
                        displayName: sender.displayName ? sender.displayName : ''
                    }
                } as TemplateData_documentAcknowledgementRequestEmail
                templateData = acknowledgementTemplateData
                templateType = 'documentAcknowledgementRequestEmail'
                break
            default:
                throw new Error('not implemented')
        }

        const emailData:IEmailTemplate = {
            to: this.recipientEmail,
            procedure: this.id,
            templateData: templateData,
            templateSearch: {
                language: language,
                entityType: TemplateEntityTypes.USER,
                entityId: sender.id,
                type: templateType
            }
        }

        await this.sendEmail(emailData, {
            document: {
                title: docData.title as string
            },
            senderEmail: sender.email
        })

        this.status = ProcedureStatus.ISSUED_NOTIFICATION
        await this.save()
    }

    protected async changeRecipient(options:ProcedureChangeRecipientOptions) {
        this.recipientEmail = options.newEmail
        if (options.restartProcedure) {
            this.status = ProcedureStatus.CREATED
            await this.reducer({ type: ProcedureActionType.CREATED })
        } 
            else await this.save()
    }

    protected async reducerAcknowledgement(action:ProcedureAction) {
        switch (action.type) {
            case ProcedureActionType.UPDATED:
                if (this.status === ProcedureStatus.WAITING_DOCUMENT) await this.sendDocumentNotification()
                break
            case ProcedureActionType.CREATED: await this.sendDocumentNotification(); break
            case ProcedureActionType.CHANGE_RECIPIENT: await this.changeRecipient(action.data); break
            case ProcedureActionType.NOTIFICATION_SENT: await this.notificationSent(); break
            case ProcedureActionType.ACKNOWLEDGED: await this.requestFulfilled(); break
            default: throw new Error(`action "${ action.type }" not implemented`)
        }
    }

    protected async reducerNotificationDocumentUploaded(action:ProcedureAction) {
        switch (action.type) {
            case ProcedureActionType.UPDATED:
                if (this.status === ProcedureStatus.WAITING_DOCUMENT) await this.sendDocumentNotification()
                break
            case ProcedureActionType.CREATED: await this.sendDocumentNotification(); break
            case ProcedureActionType.CHANGE_RECIPIENT: await this.changeRecipient(action.data); break
            case ProcedureActionType.NOTIFICATION_SENT: await this.notificationSent(); break
            default: throw new Error(`action "${ action.type }" not implemented`)
        }
    }

    protected async reducerFile(action:ProcedureAction) {
        switch (action.type) {
            case ProcedureActionType.CREATED: break
            case ProcedureActionType.COPY:
                const documentId = action.data
                await this.copyFile(documentId)
                await this.ended()
                break
            case ProcedureActionType.SPLIT:
                const data = action.data as ProcedureSplitFileDataEl[]
                await this.splitFile(data)
                await this.ended()
                break
            default: throw new Error(`action "${ action.type }" not implemented`)
        }
    }

    async reducer(action:ProcedureAction) {
        try {
            switch (this.type) {
                case ProcedureType.ACKNOWLEDGEMENT: await this.reducerAcknowledgement(action); break
                case ProcedureType.NOTIFICATION_DOCUMENT_UPLOADED: await this.reducerNotificationDocumentUploaded(action); break
                case ProcedureType.FILE: await this.reducerFile(action); break
                default: throw new Error(`procedure "${ this.type }" not implemented`)
            }
        } catch (e) {
            console.error(e)
            this.output = e.message
            this.status = ProcedureStatus.ERROR
            await this.save()
            await this.onError(e)
        }
    }
}

export default AbsProcedure