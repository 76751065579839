import React, { forwardRef, useImperativeHandle } from "react"
import { Snackbar } from "@material-ui/core"

const ErrorSnackbarBase = function(props:{}, ref: any) {
    const [data, setData] = React.useState({
        opened: false,
        message: ''
    })

    function open(message:string) {
        setData({
            ...data,
            opened: true,
            message: message
        })
    }

    useImperativeHandle(ref, () => {
        return { open: open }
    })

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={ 3000 }
            open={ data.opened }
            onClose={ () => setData({ ...data, opened: false }) }
            message={  data.message }
        />
    )
}

const ErrorSnackbar = forwardRef(ErrorSnackbarBase)
export default ErrorSnackbar