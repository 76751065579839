import React from 'react'
import SideMenuLogged from './SideMenuLogged'
import SideMenuUnlogged from './SideMenuUnlogged'
import { UserContext, UserStatus } from '../Models/User'

const SideMenu = () => {
    const currentUser = React.useContext(UserContext).currentUser
    if (currentUser.getStatus() === UserStatus.UNKNOWN) return null

    return ( currentUser.getStatus() === UserStatus.LOGGED ? <SideMenuLogged /> : <SideMenuUnlogged /> )
}

export default SideMenu