import React from "react"
import { useTranslation } from "react-i18next"
import { Paper, List, ListItem, ListItemText } from "@material-ui/core"
import { UserContext } from "../Models/User"

export interface DocumentsTableMenuProps {
    onClickHistory: () => void
    onClickDelete: () => void
    onClickErase: () => void
}

const DocumentsTableMenu:React.SFC<DocumentsTableMenuProps> = function ({ onClickHistory, onClickDelete, onClickErase }) {
    const { t } = useTranslation()
    const currentUser = React.useContext(UserContext).currentUser

    return (
        <Paper>
            <List dense>
                <ListItem button onClick={ onClickHistory }>
                    <ListItemText primary={ t('Show history') } />
                </ListItem>
                <ListItem button onClick={ onClickDelete }>
                    <ListItemText primary={ t('Delete') } style={{color:"red"}} />
                </ListItem>
                {
                    (currentUser.isAdmin ? (

                <ListItem button onClick={ onClickErase }>
                    <ListItemText primary={ t('Erase') } style={{color:"red"}} />
                </ListItem>

                    ) : null)
                }
            </List>
        </Paper>
    )
}

export default DocumentsTableMenu