import { objectAttributes } from './Misc'
import _ from 'lodash'
import { TemplateData_documentAcknowledgementRequestEmail } from './templateTypes/documentAcknowledgementRequestEmail'
import { TemplateData_documentUploadedEmail } from './templateTypes/documentUploadedNotificationEmail'
import { platformLanguages } from './Constants'

export enum TemplateEntityTypes {
    SYSTEM = 'system',
    USER = 'user'
}

export interface TemplateListing {
    language?: string
    entityType?: TemplateEntityTypes
    entityId?: string
}

export interface TemplateSearch extends TemplateListing {
    language: string
    entityType: TemplateEntityTypes
    type: string
}

export interface IAbsTemplate extends TemplateSearch {
    id?: string
    subject?: string,
    html?: string
}

export interface TemplateRetrieveOptions {
    fallback?: boolean
    saveFallbackRetrieved?: boolean,
    onFallbackKeepId?: boolean // when the fallback is not saved as new template it keeps the id. WARNING if true => saving the template will overwrite the template chosen as fallback
}

export const templateTypes = {
    'documentAcknowledgementRequestEmail': {
        label: 'Document Acknowledgement Request', // t('Document Acknowledgement Request')
        dataModel: {
            recipient: {
                displayName: ''
            },
            document: {
                title: '',
                link: ''
            },
            sender: {
                displayName: ''
            }
        } as TemplateData_documentAcknowledgementRequestEmail
    },
    'documentUploadedEmail': {
        label: 'Document Uploaded Notification', // t('Document Uploaded Notification')
        dataModel: {
            recipient: {
                displayName: ''
            },
            document: {
                title: ''
            },
            sender: {
                displayName: ''
            }
        } as TemplateData_documentUploadedEmail
    }
}

abstract class AbsTemplate implements IAbsTemplate {
    public id?: string
    public type: string
    public language: string
    public entityType: TemplateEntityTypes
    public entityId?: string
    public subject?: string
    public html = ''

    constructor(data: IAbsTemplate) {
        this.id = data.id
        this.type = data.type
        this.language = data.language
        this.entityType = data.entityType
        this.entityId = data.entityId
        this.subject = data.subject
        if (data.html) this.html = data.html
    }

    getSnapData() {
        const data:IAbsTemplate = {
            type: this.type,
            language: this.language,
            entityType: this.entityType,
            html: this.html
        }

        if (this.entityId) data.entityId = this.entityId
        if (this.subject) data.subject = this.subject
        
        return data
    }

    getTokens() { return AbsTemplate.getTokens(this.type) }
    getLabel(t:Function) { return AbsTemplate.getLabel(this, t) }

    static getLabel(data:TemplateSearch, t:Function) {
        //@ts-ignore
        let result = t(templateTypes[data.type].label)
        result += ` (${data.language})`

        return result
    }

    static getTokens(type:string) {
        //@ts-ignore
        const model = templateTypes[type].dataModel
        return objectAttributes(model).map( el => '{{' + el + '}}' )
    }

    static getList(query:TemplateListing):TemplateSearch[] {
        let result = [] as TemplateSearch[]
        const language = query.language
        const entityType = query.entityType

        if (!language) {
            for (const lang of platformLanguages) {
                const newQuery = _.clone(query)
                newQuery.language = lang
                result = result.concat( this.getList(newQuery) )
            }
            return result
        }

        if (!entityType) {
            for (const type of Object.values(TemplateEntityTypes)) {
                const newQuery = _.clone(query)
                newQuery.entityType = type
                result = result.concat( this.getList(newQuery) )
            }
            return result
        }

        for (const type in templateTypes) {
            result.push({
                type: type,
                language: language,
                entityType: entityType,
                entityId: query.entityId
            })
        }  
        
        return result
    }
}

export default AbsTemplate