import { Grid } from "@material-ui/core"
import React from "react"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
//import TemplateEditableList from "../Cards/TemplateEditableList"
import Layout from '../Components/Layout'
import { FirebaseContext } from "../Models/Firebase"
import { DataStatus } from '../Shared/Misc'
//import { TemplateEntityTypes } from "../Shared/Template"
import User, { UserContext, UserStatus } from "../Models/User"
import { AccessDenied, PageNotFound } from "./MessageWithLayout"
import UserDisruptiveActions from "../Cards/UserDisruptiveActions"

const UserPage:React.SFC<{}> = () => {
    const firebase = React.useContext(FirebaseContext)
    const currentUser = React.useContext(UserContext).currentUser
    const { t } = useTranslation()
    const [ data, setData ] = React.useState({
        status: DataStatus.LOADING,
        user: null as User | null
    })
    const location = useLocation()
    const locationPieces = location.pathname.split('/')
    let pageUserId:string

    async function getData() {
        try {
            if (!firebase) throw new Error('no firebase')
            const user = await User.getById(firebase, pageUserId, { forceFromNetwork: true })

            if (!user) {
                setData({ ...data, status: DataStatus.EMPTY })
                return
            }
            user.loadCustomClaims(firebase)
            setData({
                ...data,
                user: user,
                status: DataStatus.READY
            })

        } catch (e) {
            console.error(e)
        }
    }
    
    React.useEffect(() => {
        if (data.status === DataStatus.LOADING && currentUser.getStatus() === UserStatus.LOGGED) getData()
    })

    if (locationPieces.length !== 3) return (<PageNotFound />)
    pageUserId = locationPieces[2]
    if (!pageUserId.match(/[a-z0-9]{11}/i) || data.status === DataStatus.EMPTY) return (<PageNotFound />)
    
    if (!firebase || currentUser.getStatus() === UserStatus.UNKNOWN || data.status === DataStatus.LOADING) return null
    if (currentUser.getStatus() === UserStatus.ANONYMOUS || !data.user || (!currentUser.isAdmin && currentUser.id !== data.user.id))
        return (<AccessDenied title={ t('User') } />)


    const title = data.user.displayName
    return (
        <Layout title={ title }>
            <Grid container spacing={ 2 } alignContent="space-between">
                <Grid item xs={ 12 } md={ 6 }>
                    <UserDisruptiveActions />
                </Grid>
            </Grid>
        </Layout>
    )
}

export default UserPage