import { Badge, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, LinearProgress, Typography } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete'
import React, { createRef, useRef } from "react"
import { Trans, useTranslation } from "react-i18next"
import DocumentEdit from "../Forms/DocumentEdit"
import { FirebaseContext } from "../Models/Firebase"
import Procedure from "../Models/Procedure"
import { UserContext, UserStatus } from "../Models/User"
import { DocStatus, DocType, IDoc } from "../Shared/Doc"
import FileDocs from "../Shared/FileDocs"
import { ProcedureActionType, ProcedureSplitFileDataEl, ProcedureType } from "../Shared/Procedure"
import DocumentAdd from "../Cards/DocumentAdd"

export interface ReviewUploadedFileProps {
    fileDocsGetter: () => FileDocs
    tmpFilePath: string
    onCancel: () => void
    onCompleted: () => void
    test?: boolean
}

enum SendingStatus {READY = 'ready', SENT = 'sent', SENDING = 'sending', ERROR = 'error'}

interface CardData {
    ref: React.RefObject<any>
    hasValidData: boolean
    sendingStatus: SendingStatus
    id?: string
}

function getSubmitButton(numOfErrors:number, onSubmit:any) {
    const submitButtonBase = (
        <Button onClick={ onSubmit } variant="contained" disabled={ numOfErrors > 0 } color="primary">
            <Trans>Submit</Trans>
        </Button>
    )

    let submitButton:JSX.Element
    if (numOfErrors > 0) {
        submitButton = (
            <Badge badgeContent={ numOfErrors } color="error">
                { submitButtonBase }
            </Badge>
        )
    } else {
        submitButton = submitButtonBase
    }

    return submitButton
}

const ReviewUploadedFile = ({ tmpFilePath, fileDocsGetter, onCancel, onCompleted, test = false }:ReviewUploadedFileProps) => {
    const fileDocsRef = useRef( fileDocsGetter() )
    const isSendingRunningRef = useRef( false )
    const firebase = React.useContext(FirebaseContext)
    const currentUser = React.useContext(UserContext).currentUser
    const cardDataListRef = useRef( fileDocsGetter().sections.map( () => {
        const el:CardData = {
            ref: createRef(),
            hasValidData: false,
            sendingStatus: SendingStatus.READY
        }
        return el
    } ))

    const { t } = useTranslation()
    
    const [data, setData] = React.useState({
        numOfErrors: fileDocsGetter().sections.length,
        sendingProgress: 0,
        isSending: false,
        cardDeleted: fileDocsGetter().sections.map( () => false ) as boolean[],
        tmpFileUrl: ''
    })

    function onValidityChanged(idx:number, isValid:boolean) {
        cardDataListRef.current[idx].hasValidData = isValid
        const numOfErrors = cardDataListRef.current.reduce( 
            (prevErrors, cardData) => prevErrors + (cardData.hasValidData ? 0 : 1),
            0
        )
        setData({
            ...data,
            numOfErrors: numOfErrors
        })
    }

    function onSubmit() {
        const cardDataList = cardDataListRef.current
        if (cardDataList.length === 0) { onCompleted(); return }
        for (var i in cardDataList) 
            if (cardDataList[i].sendingStatus === SendingStatus.ERROR) cardDataList[i].sendingStatus = SendingStatus.READY
        setData({
            ...data,
            sendingProgress: 0,
            isSending: true
        })
    }

    async function sendingData() {
        if (isSendingRunningRef.current) return
        isSendingRunningRef.current = true
        const cardDataList = cardDataListRef.current
        let areThereErrors = false
        let numOfSendingCompleted = 0

        await Promise.all( cardDataList.map(
            async (cardData, pos) => {
                try {
                    if (!data.cardDeleted[pos]) {
                        cardData.id = await cardData.ref.current.save()
                        cardData.sendingStatus = SendingStatus.SENT
                    }
                } catch (e) {
                    console.error(e)
                    cardData.sendingStatus = SendingStatus.ERROR
                    areThereErrors = true
                } finally {
                    ++numOfSendingCompleted
                    setData({
                        ...data,
                        sendingProgress: 100 * numOfSendingCompleted / cardDataList.length
                    })
                }    
            }
        ) )

        if (areThereErrors) { // done with errors
            setData({
                ...data,
                sendingProgress: 0,
                isSending: false
            })
            isSendingRunningRef.current = false
            return
        }

        if (!firebase) throw new Error('no firebase')
        if (currentUser.getStatus() !== UserStatus.LOGGED) throw new Error('user not logged')

        const actions:ProcedureSplitFileDataEl[] = fileDocsRef.current.sections
            .map(
                (section, pos) => {
                    const docId = cardDataListRef.current[pos].id as string
                    let cardExtra:ProcedureSplitFileDataEl = {
                        from: section.startPage,
                        to: section.endPage,
                        documentId: docId
                    }
                    return cardExtra
                }
            )
            .filter(
                (extra, pos) => !data.cardDeleted[pos]
            )

        const procedure = new Procedure({
            issuer: currentUser.id as string,
            type: ProcedureType.FILE,
            handler: tmpFilePath
        })
        await procedure.save(firebase)
        
        if (actions.length === 1 && actions[0].from === 1 && actions[0].to === fileDocsRef.current.pages.length) {
            await procedure.sendAction(firebase, {
                type: ProcedureActionType.COPY,
                data: actions[0].documentId
            })
        } else {
            await procedure.sendAction(firebase, {
                type: ProcedureActionType.SPLIT,
                data: actions
            })
        }
        onCompleted()
    }

    function onDeleteCard(pos: number) {
        if (!test)
            if (!window.confirm(t('Do you really want to delete this document?'))) return
        data.cardDeleted[pos] = true
        if (!cardDataListRef.current[pos].hasValidData) --data.numOfErrors
        cardDataListRef.current[pos].hasValidData = true
        setData({
            ...data,
            numOfErrors: data.numOfErrors,
            cardDeleted: data.cardDeleted
        })
    }

    function onAddDocument(docType:DocType, from:number, to:number) {
        const newDataDoc:IDoc = {
            sender: currentUser.id!,
            type: docType
        }

        fileDocsRef.current.sections.push({
            startPage: from,
            endPage: to,
            dataDoc: newDataDoc
        })

        const el:CardData = {
            ref: createRef(),
            hasValidData: false,
            sendingStatus: SendingStatus.READY
        }

        cardDataListRef.current.push(el)
        data.cardDeleted.push(false)
        setData({
            ...data,
            cardDeleted: data.cardDeleted
        })
        onValidityChanged(cardDataListRef.current.length - 1, false)
    }

    async function retrievePreviewUrl() {
        const url = await firebase!.app.storage().ref(tmpFilePath).getDownloadURL()
        setData({
            ...data,
            tmpFileUrl: url
        })
    }

    const documentReviews = fileDocsRef.current.sections.map( (section, idx) => {
        const cardData = cardDataListRef.current[idx]
        let title:string
        let pages:string
        
        section.dataDoc.status = DocStatus.CREATION
        switch (section.dataDoc.type) {
            case DocType.PAYCHECK:
                title = t('Paycheck')
                break
            case DocType.UNIQUE_CERTIFICATION:
                title = t('Unique Certification')
                break
            default:
                title = t('Generic Document')
                break
        }

        if (section.startPage === section.endPage) pages = t('page') + ' ' + (section.startPage)
            else pages = t('pages: {{from}} - {{to}}', {
                from: section.startPage ,
                to: section.endPage
            })

        return (
            <Grid item key={ idx } hidden={ data.isSending || data.cardDeleted[idx] } style={{width:'100%'}}>
                <Card>
                    <CardHeader
                        title={  title }
                        subheader={ pages }
                        action={
                            (<IconButton aria-label={t('delete')} onClick={ () => onDeleteCard(idx) } >
                                <DeleteIcon />
                            </IconButton>)
                        }
                    />
                    <CardContent>
                        <DocumentEdit
                            key={ idx }
                            hide={ data.isSending }
                            ref={ cardData.ref }
                            dataDoc={ section.dataDoc }
                            onValidityChanged={ (isValid) => onValidityChanged(idx, isValid) }
                            test = { test }
                        />
                    </CardContent>
                </Card>
            </Grid>
        )
    })

    React.useEffect(() => {
        if (!data.tmpFileUrl && firebase) retrievePreviewUrl()
        if (data.isSending && !isSendingRunningRef.current) sendingData()
    })

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    { data.isSending ? (<Trans>Submitting Data...</Trans>) : (<Trans>Review File</Trans>) }
                </Typography>
                <LinearProgress style={{ marginBottom: "1em" }} variant="determinate" value={ data.sendingProgress } hidden={!data.isSending} />
                <Grid container direction="row" spacing={1} alignItems="stretch">
                    <Grid item md={6} hidden={data.isSending}>
                        <iframe style={{width:'100%', height:'100%'}} title="file preview" src={data.tmpFileUrl}>file preview</iframe>
                        <Trans>file preview</Trans>
                    </Grid>
                    <Grid item container md={6} hidden={data.isSending} style={{overflowY: 'scroll'}} spacing={1} alignItems="stretch">
                        <Grid item hidden={ data.isSending } style={{width:'100%'}}>
                            <DocumentAdd 
                                onAdd={ onAddDocument }
                                maxPages={ fileDocsRef.current.pages.length }
                            />
                        </Grid>
                        { documentReviews }
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container spacing={ 2 } justify="center">
                    <Grid item hidden={data.isSending}>
                        <Button variant="contained" onClick={ onCancel }>
                            <Trans>Cancel</Trans>
                        </Button>
                    </Grid>
                    <Grid item hidden={data.isSending}>
                        { getSubmitButton(data.numOfErrors, onSubmit) }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

export default ReviewUploadedFile