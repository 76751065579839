import _ from "lodash"

export enum FiscalcodeType {
    COMPANIES = 'companies',
    PEOPLE = 'people',
    INVALID = 'invalid'
}

export const FiscalRegex = {
    companies: /\d{11}/img,
    people: /[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]/img
}

export interface GetFiscalcodeOptions {
    companies?:boolean
    people?:boolean
    tryWithoutSpaces?:boolean
    excludeFiscalcodes?: string[]
}

export enum GetFiscalcodeResultState {
    MULTI = 'multiple values',
    UNIQUE = 'unique value',
    NONE = 'none'
}

export interface GetFiscalcodeResult {
    state: GetFiscalcodeResultState,
    value?: string,
    values: string[],
    occurrences: { [key: string]: number}
}

export interface fiscalcodeErrorOptions {
    required?: boolean,
    typeRestriction?: FiscalcodeType,
    t?: Function
}

export function fiscalcodeError(fiscalcode?:string, inputOptions?:fiscalcodeErrorOptions):string {
    const identity = (err:string) => err
    const options:{required:boolean, typeRestriction?:FiscalcodeType, t:Function} = _.defaults(inputOptions, {
        required: false,
        t: identity
    })

    if (!fiscalcode)
        return options.required ? options.t('fiscalcode is required') : ''
    
    const isValidPeople = !!fiscalcode.match(FiscalRegex.people)
    const isValidCompanies = !!fiscalcode.match(FiscalRegex.companies)
    
    if (!isValidCompanies && !isValidPeople) return options.t('invalid fiscalcode')
    if (options.typeRestriction === FiscalcodeType.PEOPLE && !isValidPeople) return options.t('fiscalcode of people only')
    if (options.typeRestriction === FiscalcodeType.COMPANIES && !isValidCompanies) return options.t('fiscalcode of companies only')
    
    return ''
}

export function getFiscalcode(text:string, inputOptions?: GetFiscalcodeOptions): GetFiscalcodeResult {
    const listTypes = [FiscalcodeType.COMPANIES, FiscalcodeType.PEOPLE]
    const options:GetFiscalcodeOptions = _.defaults(inputOptions, {
        companies: true,
        people: true,
        tryWithoutSpaces: true,
        excludeFiscalcodes: []
    })

    const result:GetFiscalcodeResult = {
        state: GetFiscalcodeResultState.UNIQUE,
        values: [],
        occurrences: {}
    }

    if (options.tryWithoutSpaces) {
        options.tryWithoutSpaces = false
        const strictResult = getFiscalcode(text, options)
        if (strictResult.values.length > 0) return strictResult
        options.tryWithoutSpaces = true
    }

    listTypes.map( type => {
        // @ts-ignore
        if (!options[type]) return null
        // @ts-ignore
        const reg:RegExp = FiscalRegex[type] as RegExp
        let matches = text.match( reg )

        // try without spaces
        if (!matches && options.tryWithoutSpaces) {
            text = text.replace(/\s/mg, '')
            matches = text.match( reg )
        }

        if (!matches) return null

        //@ts-ignore
        for (let i = 0; i < matches.length; i++) {
            const match = matches[i]
            if (options.excludeFiscalcodes && options.excludeFiscalcodes.indexOf(match) !== -1) continue
            if ( result.occurrences[match] ) ++result.occurrences[match]
                else result.occurrences[ match ] = 1
        }
        return null
    })

    for (const fiscalcode in result.occurrences) result.values.push(fiscalcode)

    if (result.values.length === 1) result.value = result.values[0]
        else if (result.values.length > 1) 
            result.state = GetFiscalcodeResultState.MULTI
        else result.state = GetFiscalcodeResultState.NONE

    return result
}