import AbsAlert, { IAlertDefault, IAlert, AlertType } from "../Shared/Alert"
import Firebase, { FirebaseApp } from "./Firebase"
import CommonFirebase from "./CommonFirebase"
import { EnvName, alertsForProductionEnvOnly } from "../Shared/Constants"
import StorageIcon from '@material-ui/icons/Storage'
import ErrorIcon from '@material-ui/icons/Error'
import React from "react"
import { ListItemText } from "@material-ui/core"
import moment from 'moment'

class Alert extends AbsAlert {
    async save(firebase: Firebase) {
        if (alertsForProductionEnvOnly && this.envName !== EnvName.PRODUCTION) return
        await CommonFirebase.save(firebase, this, 'alerts')
    }

    getIcon() {
        switch (this.type) {
            case AlertType.STORAGE:
                return (<StorageIcon />)
            default: 
                return (<ErrorIcon />)
        }
    }

    getItemText() {
        switch (this.type) {
            default: 
                return (
                    <ListItemText 
                        primary={ this.message }
                        secondary={ moment(this.timestamp).fromNow() }
                    />
                )
        }
    }

    static async send(firebase: Firebase, data:IAlertDefault) {
        const alert = new Alert(data)

        if (alert.user) {
            const currentUser = firebase.app.auth().currentUser
            alert.user = currentUser ? currentUser.uid : undefined
        }
        await alert.save(firebase)
    }

    static fromSnap(snap:FirebaseApp.firestore.QueryDocumentSnapshot<FirebaseApp.firestore.DocumentData>) {
        let alert = new Alert(snap.data() as IAlert)
        alert.id = snap.id
        
        return alert
    }
}

export default Alert