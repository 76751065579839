import React from "react"
import Layout from '../Components/Layout'

const Features = () => {
    return (
        <Layout>
            <div>features</div>
        </Layout>
        
    )
}

export default Features