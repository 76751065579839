import 'firebase/analytics'
import FirebaseApp from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import _ from 'lodash'
import { Query as MaterialTableQuery, QueryResult as MaterialTableQueryResult } from 'material-table'
import React from 'react'
import { Env, EnvName, defaultLanguage } from '../Shared/Constants'
import User from './User'
import i18n from '../i18n'
import { LANDING } from '../Constants/Routes'

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

export interface FirebaseOptions {
    persistence: string
}

type FirebaseCollection = FirebaseApp.firestore.CollectionReference<FirebaseApp.firestore.DocumentData>
type FirebaseQuery = FirebaseApp.firestore.Query<FirebaseApp.firestore.DocumentData>
type FirebaseDocument = FirebaseApp.firestore.QueryDocumentSnapshot<FirebaseApp.firestore.DocumentData>
type FirebaseQuerySnapshot = FirebaseApp.firestore.QuerySnapshot<FirebaseApp.firestore.DocumentData>

class Firebase {
    public app:FirebaseApp.app.App
    public db:FirebaseApp.firestore.Firestore
    public storageRef: FirebaseApp.storage.Reference
    private unsubscribeOnAuthStateChanged?: Function
    public options:FirebaseOptions
    public analytics:FirebaseApp.analytics.Analytics

    /***
      * @param Object firebase app configuration params
      */
    constructor(configuration = firebaseConfig, inputOptions?:FirebaseOptions) {
        if (FirebaseApp.apps.length > 0) console.warn('firebase app already initialized, not initializing it again')
        this.app = FirebaseApp.initializeApp(configuration)
        this.db = this.app.firestore()
        this.storageRef = this.app.storage().ref()
        this.analytics = this.app.analytics()
        this.options = _.defaults(inputOptions, {
            persistence: FirebaseApp.auth.Auth.Persistence.SESSION
        })
        if (Env.get().name !== EnvName.PRODUCTION) this.app.functions('europe-west1').useFunctionsEmulator('http://localhost:5001')
        this.app.auth().setPersistence(this.options.persistence)
    }

    setOnAuthStateChangedCallback(onAuthStateChanged:Function | null) {
        if (onAuthStateChanged) {
            this.unsubscribeOnAuthStateChanged = this.app.auth().onAuthStateChanged( () => {
                onAuthStateChanged(this.app.auth().currentUser)
            })
        } else 
            if (this.unsubscribeOnAuthStateChanged) this.unsubscribeOnAuthStateChanged()
    }

    hasOnAuthStateChanged(): Boolean {
        return !!this.unsubscribeOnAuthStateChanged
    }

    /***
     * If the user is the current user => it will update current claims
     * @return boolean (false: user not found or something similar)
     */
    async syncUserCustomClaimByEmail(email:string):Promise<boolean> {
        const syncUserCustomClaimsByEmail = this.app.functions('europe-west1').httpsCallable('syncUserCustomClaimsByEmail')
        const firebaseUser = this.app.auth().currentUser
        if (email === firebaseUser?.email) return this.syncCurrentUserCustomClaims().then(() => true).catch(() => false)
        try {
            await syncUserCustomClaimsByEmail(email)
            return true
        } catch(e) {
            console.error(e)
            return false
        }

    }

    async syncCurrentUserCustomClaims() {
        const firebaseUser = this.app.auth().currentUser
        const syncUserCustomClaims = this.app.functions('europe-west1').httpsCallable('syncUserCustomClaims')
        if (!firebaseUser) throw new Error('firebaseUser should not be null in syncCurrentUserCustomClaims')

        await syncUserCustomClaims(firebaseUser.uid)
        await firebaseUser.getIdToken(true)
        let idTokenResult = await firebaseUser.getIdTokenResult()
        return User.getClaimsFromData(idTokenResult.claims)
    }

    async retrieveCurrentUserCustomClaims() {
        const firebaseUser = this.app.auth().currentUser
        if (!firebaseUser) throw new Error('firebaseUser should not be null in syncCurrentUserCustomClaims')
        
        let idTokenResult = await firebaseUser.getIdTokenResult()
        let claims = User.getClaimsFromData(idTokenResult.claims)

        if (claims.admin === undefined) return await this.syncCurrentUserCustomClaims()
        return claims
    }

    async retrieveCurrentUser() {
        const firebaseUser = this.app.auth().currentUser
        let toBeUpdated = false
        if (!firebaseUser) {
            const user = new User()
            user.setAnonymous()
            return user
        }

        let user = await User.getById(this, firebaseUser.uid, {
            saveInSessionStorage: false,
            forceFromNetwork: true
        })

        const firebaseUserData = Firebase.getDataFromFirebaseUser(firebaseUser)
        const languageToSet = (!user || !user.language) ? User.detectBrowser().language : ''

        if (!user) {
            user = User.fromFirebaseAuth(firebaseUserData)
            toBeUpdated = true
        } else toBeUpdated = user.updateByFirebaseAuth(firebaseUserData)

        if (languageToSet) {
            user.language = languageToSet
            toBeUpdated = true
        }

        if (user.language !== defaultLanguage)
            await i18n.changeLanguage(user.language)

        if (toBeUpdated) user.save(this)
        await user.loadCustomClaims(this)

        return user
    }

    async logout(updateCurrentUser:Function, historyLocation:any) {
        try {
            this.analytics.logEvent('logout')
            await this.app.auth().signOut()
            let user = new User()
            user.setAnonymous()
            updateCurrentUser(user)
            historyLocation.push(LANDING)
        } catch (e) {
            console.error(e)
        }
    }

    /**
     * This function get a custom token to bypass real login
     * It will work only in development env
     */
    async fakeLogin(email:string) {
        const getCustomToken = this.app.functions('europe-west1').httpsCallable('getCustomToken')
        const result = await getCustomToken(email)

        return this.app.auth().signInWithCustomToken(result.data.token)
    }

    async fileExists(path:string) {
        const fileExists = this.app.functions('europe-west1').httpsCallable('fileExists')
        const result = await fileExists(path)

        return result
    }

    async retrieveDataForMaterialTable<T extends object>(
        collection: FirebaseQuery | FirebaseCollection,
        params:MaterialTableQuery<T>,
        transform: (arg: FirebaseDocument) => T,
        previousPage: number,
        previousSnapshot?: FirebaseQuerySnapshot
    ): Promise<{
        materialTableData:MaterialTableQueryResult<T>, 
        snapshot?: FirebaseQuerySnapshot
    }> {
        let query = collection
        let data:T[] = []
        const size = (await query.get()).size
        const lastPage = Math.floor(size / params.pageSize)

        if (!params.orderBy) throw new Error('unsorted data')
        query = query.orderBy(params.orderBy.field as string, params.orderDirection)

        if (params.page === 0) query = query.limit(params.pageSize)
            else if (params.page === lastPage) query = query.limitToLast(size % params.pageSize)
        else {
            if (!previousSnapshot || previousSnapshot.empty) throw new Error('unable to go to a different page without previousSnapshot')
            let previousDocs = previousSnapshot.docs
            if (params.page === previousPage + 1) query = query.startAfter(previousDocs[previousDocs.length - 1]).limit(params.pageSize)
                else if (params.page === previousPage - 1) query = query.endBefore(previousDocs[0]).limitToLast(params.pageSize)
                else throw new Error(`Skipping page from page ${previousPage} to page ${params.page} is not implemented`)
        }

        const snap = await query.get()
        snap.forEach( doc => data.push( transform(doc) ))

        const result:MaterialTableQueryResult<T> = {
            data: data,
            page: params.page,
            totalCount: size
        }

        return {
            materialTableData: result,
            snapshot: snap
        }
    }

    

    static getDataFromFirebaseUser(firebaseUser:FirebaseApp.User) {
        let data:any = {}

        if (firebaseUser.displayName) data.displayName = firebaseUser.displayName
        if (firebaseUser.photoURL) data.photoURL = firebaseUser.photoURL
        data.id = firebaseUser.uid
        data.email = firebaseUser.email

        return data
    }
}

export default Firebase
export const FirebaseContext = React.createContext(null as Firebase | null)
export { FirebaseApp }
