import { Backdrop, Button, CircularProgress, createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MuiThemeProvider } from "@material-ui/core"
import red from '@material-ui/core/colors/red'
import React, { forwardRef, useImperativeHandle } from "react"
import { Trans } from "react-i18next"
import { useHistory } from "react-router-dom"
import ErrorSnackbar from "../Components/ErrorSnackbar"
import Doc from "../Models/Doc"
import ErrorManager, { NO_FIREBASE, USER_NOT_LOGGED } from "../Models/ErrorManager"
import { FirebaseContext } from "../Models/Firebase"
import User, { UserContext } from "../Models/User"
import Occurrence from "../Models/Occurrence"
import { OccurrenceAction, OccurrenceEntity } from "../Shared/Occurrence"

const redTheme = createMuiTheme({ palette: { primary: red } })

export interface UserDeleteProps {
    onUserDeleted?: () => void
}

const UserDeleteBase = function({ onUserDeleted }:UserDeleteProps, ref: any) {
    const firebase = React.useContext(FirebaseContext)
    const userContext = React.useContext(UserContext)
    const updateCurrentUser = userContext.updateCurrentUser
    const currentUser = userContext.currentUser
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const history = useHistory()
    const [data, setData] = React.useState({
        opened: false,
        user: null as User | null,
        loading: false
    })

    function open(user:User) {
        setData({
            ...data,
            opened: true,
            user: user
        })
    }

    useImperativeHandle(ref, () => {
        return { open: open }
    })

    function onClose () {
        setData({
            ...data,
            opened: false
        })
    }

    async function onSubmit () {
        try {
            setData({ ...data, opened: false, loading:true })
            if (!firebase) throw new Error(NO_FIREBASE)
            if (!currentUser.id) throw new Error(USER_NOT_LOGGED)
            if (!data.user) throw new Error('user should not be null')
            const user = data.user

            const contacts = await firebase.db.collection('contacts').where('owner', '==', data.user.id).get()
            contacts.forEach( async snap => { await snap.ref.delete() })

            const docs = await firebase.db.collection('documents').where('sender', '==', data.user.id).get()
            docs.forEach( async snap => {
                const doc = Doc.fromSnap(snap)
                doc.deleted = true
                await doc.save(firebase)
                const occurrence = new Occurrence({
                    action: OccurrenceAction.DELETED,
                    entity: OccurrenceEntity.DOCUMENT,
                    actor: user.email
                })
                await occurrence.save(firebase)
            })

            data.user.deleted = true
            await data.user.save(firebase)
            const occurrence = new Occurrence({
                action: OccurrenceAction.DELETED,
                entity: OccurrenceEntity.USER,
                actor: user.email
            })
            await occurrence.save(firebase)
            
            if (onUserDeleted) onUserDeleted()
            firebase.logout(updateCurrentUser, history)
        } catch(e) {
            setData({ ...data, loading:false })
            //@ts-ignore
            ErrorManager.query(firebase, e, errorSnackbarRef.current.open)
        }
    }
    
    return (
        <React.Fragment>
            <ErrorSnackbar ref={ errorSnackbarRef } />

            <Dialog open={ data.loading }>
                <Backdrop open={ data.loading }>
                    <CircularProgress color="secondary" />
                </Backdrop>
            </Dialog>

            <Dialog 
                fullWidth
                open={ data.opened }
                onClose={ onClose }
                aria-labelledby="user-delete-dialog-title"
            >
                <DialogTitle id="user-delete-dialog-title">
                    <Trans>Do you really want to deleted this account?</Trans>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="user-delete-dialog-description">
                    <Trans>This operation will delete all the documents owned by you and all your contacts</Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ onClose } color="secondary">
                        <Trans>Cancel</Trans>
                    </Button>
                    <MuiThemeProvider theme={ redTheme }>
                        <Button onClick={ onSubmit } color="primary" autoFocus>
                            <Trans>Delete</Trans>
                        </Button>
                    </MuiThemeProvider>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const UserDelete = forwardRef(UserDeleteBase)
export default UserDelete