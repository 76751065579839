import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import React, { forwardRef, Suspense, useImperativeHandle } from "react"
import { Trans, useTranslation } from "react-i18next"
import ErrorSnackbar from "../Components/ErrorSnackbar"
import i18n from '../i18n'
import { FirebaseContext } from "../Models/Firebase"
import { UserContext } from "../Models/User"

const PrivacyPolicyIt = React.lazy(() => import('../Templates/it/PrivacyPolicy'))

const PrivacyPolicyContent = {
    it: PrivacyPolicyIt
}

const PrivacyPolicyBase = ( props:{}, ref: any ) => {
    const { t } = useTranslation()
    const currentUser = React.useContext(UserContext).currentUser
    const firebase = React.useContext(FirebaseContext)
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const [status, setStatus] = React.useState({  opened: false })

    function open() { 
        setStatus({ ...status, opened: true })
    }

    useImperativeHandle(ref, () => {
        return { open: open }
    })
    
    function onCloseWithoutSaving() {
        const message = t("Without accepting privacy policy, it is not possible to continue on this site")
        //@ts-ignore
        errorSnackbarRef.current.open(message)
    }

    async function onAccepted() {
        try {
            if (!firebase) throw new Error('no firebase')
            await currentUser.savePrivacyPolicySigned(firebase)
            setStatus({ ...status, opened: false })
        } catch (e) {
            console.error(e)
        }

    }

    // @ts-ignore
    const ContentComponent = PrivacyPolicyContent[i18n.language]

    return (
        <React.Fragment>
        <ErrorSnackbar ref={errorSnackbarRef} />
        <Dialog 
            maxWidth="xl"
            open={ status.opened }
            onClose={ onCloseWithoutSaving }
            aria-labelledby="privacy-policy-dialog-title"
        >
            <DialogTitle id="privacy-policy-dialog-title">
                <Trans>Privacy Policy</Trans>
            </DialogTitle>
            <DialogContent>
                <Suspense fallback={<CircularProgress />}>
                    <ContentComponent />
                </Suspense>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onAccepted } color="primary">
                    <Trans>Accept</Trans>
                </Button>
            </DialogActions>
        </Dialog>

        </React.Fragment>
    )
}

const PrivacyPolicy = forwardRef(PrivacyPolicyBase)
export default PrivacyPolicy