import ErrorIcon from '@material-ui/icons/Error'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import TimerIcon from '@material-ui/icons/Timer'
import React from "react"
import i18n from '../i18n'
import AbsDoc, { DocStatus, IDoc } from "../Shared/Doc"
import { ProcedureActionType, ProcedureChangeRecipientOptions } from "../Shared/Procedure"
import CommonFirebase from "./CommonFirebase"
import ErrorManager, { displayMessage } from "./ErrorManager"
import Firebase, { FirebaseApp } from "./Firebase"
import Procedure from './Procedure'
import User from './User'
import { PersonalInfo } from '../Shared/User'

type DocumentSnapshot = FirebaseApp.firestore.QueryDocumentSnapshot<FirebaseApp.firestore.DocumentData>

class Doc extends AbsDoc {
    senderPersonalInfo?: PersonalInfo
    recipientsPersonalInfo:PersonalInfo[] = []

    async open(firebase: Firebase | null, displayErrorMessage?:displayMessage) {
        let success = true
        try {
            if (this.status === DocStatus.CREATION) {
                const message = 'Document not ready, yet. Please try again in few seconds'
                console.warn(message)
                if (displayErrorMessage) displayErrorMessage( i18n.t(message) )
                return false
            }
            if (!firebase) throw new Error('no firebase')
            const ref = firebase.storageRef.child(this.getPdfPath())
            const url = await ref.getDownloadURL()
            window.open(url, '_blank')
        } catch (e) {
            await ErrorManager.file(firebase, e, this.getPdfPath(), displayErrorMessage)
            success = false
        }
        return success
    }
    
    async save(firebase: Firebase) {
        await CommonFirebase.save(firebase, this, 'documents')
    }

    async delete(firebase: Firebase) {
        await CommonFirebase.delete(firebase, this, 'documents')
    }

    async retrieveProcedure(firebase:Firebase, recipientEmail:string) {
        const procedures = firebase.db.collection('procedures')
        const snapList = await procedures.where('handler', '==', this.id).where('recipientEmail', '==', recipientEmail).limit(1).get()
        if (snapList.empty) return null
        
        const snap = snapList.docs[0]
        return Procedure.fromSnap(snap)
    }

    async retrievePersonalInfo(firebase:Firebase, basedOn='users') {
        enum Types {ID, EMAIL}

        async function getBy(type:Types, search:string) {
            switch (basedOn) {
                case 'users':
                    if (type === Types.ID) return User.getById(firebase, search)
                    if (type === Types.EMAIL) return User.getByEmail(firebase, search)
                    break
                default:
                    throw new Error(`unrecognized "${basedOn}" type for retrievePersonalInfo`)
            }
        }

        const sender = await getBy(Types.ID, this.sender)
        if (sender) {
            this.senderPersonalInfo = {
                name: sender.displayName!,
                photoURL: sender.photoURL!,
                email: sender.email
            }
        } else {
            this.senderPersonalInfo = {
                name: this.id!,
                photoURL: '',
                email: ''
            }
        }

        this.recipientsPersonalInfo = []
        for (const recipientEmail of this.recipientEmails) {
            const recipient = await getBy(Types.EMAIL, recipientEmail)
            if (recipient) {
                const personalInfo:PersonalInfo = {
                    name: recipient.displayName!,
                    photoURL: recipient.photoURL!,
                    email: recipient.email
                }
                this.recipientsPersonalInfo.push(personalInfo)
            } else {
                this.recipientsPersonalInfo.push({
                    photoURL: '',
                    email: recipientEmail,
                    name: ''
                })
            }
        }
    }

    protected async onRecipientEmailChangedUpdateRelatedProcedures(firebase:Firebase, previousEmail: string, email: string, restartProcedures: boolean) {
        const procedure = await this.retrieveProcedure(firebase, previousEmail)
        if (!procedure) return

        const options:ProcedureChangeRecipientOptions = {
            newEmail: email,
            restartProcedure: restartProcedures
        }
        await procedure.sendAction(firebase, {
            type: ProcedureActionType.CHANGE_RECIPIENT,
            data: options
        })
    }

    async updateRecipientEmail(firebase:Firebase, previousEmail: string, email: string, restartProcedures: boolean) {
        for (var i = 0; i < this.recipientEmails.length; i++)
            if (this.recipientEmails[i] === previousEmail) {
                this.recipientEmails.splice(i, 1, email)
                await this.save(firebase)
                await this.onRecipientEmailChangedUpdateRelatedProcedures(firebase, previousEmail, email, restartProcedures)
                break
            }
    }

    renderAlertsInTable(tableType:string) {
        switch (this.status) {
            case DocStatus.ERROR:       return (<ErrorIcon />)
            case DocStatus.CREATION:    return (<TimerIcon />)
            case DocStatus.WAITING_RESPONSE:
                if (tableType === 'sent') return (<HourglassEmptyIcon />)
                if (tableType === 'received') return (<NotificationsActiveIcon />)
        }
        return null
    }

    static fromSnap(snap:DocumentSnapshot) {
        let doc = new Doc(snap.data() as IDoc)
        doc.id = snap.id
        return doc
    }
}

export default Doc