import { EnvName, Env } from "./Constants"

export enum AlertEntityType {
    USER = 'user',
    PLATFORM = 'platform'
}

export enum AlertType {
    FILE_NOT_FOUND = 'file not found',
    FILE_ACCESS_DENIED = 'file access denied',
    STORAGE = 'storage',
    INDEX_REQUIRED = 'index required',
    USER_NOT_LOGGED = 'user not logged',
    DB = 'db',
    PROCEDURE = 'procedure'
}

export interface IAlertDefault {
    id?: string,
    message: string,
    stack?: string,
    type: AlertType,
    user?: string
    envName?: EnvName,
    entityType: AlertEntityType,
    entityId?: string,
    timestamp?: string,
}

export interface IAlert extends IAlertDefault {
    envName: EnvName,
    timestamp: string
}

class AbsAlert implements IAlert {
    id?: string
    message: string
    stack?: string
    type: AlertType
    user?: string
    envName: EnvName
    entityType: AlertEntityType
    entityId?: string
    timestamp: string

    constructor(data:IAlertDefault) {
        this.id = data.id
        this.message = data.message
        this.stack = data.stack
        this.type = data.type
        this.user = data.user
        this.envName = data.envName ? data.envName: Env.get().name
        this.entityType = data.entityType
        this.entityId = data.entityId
        this.timestamp = data.timestamp ? data.timestamp : (new Date()).toISOString()
    }

    getSnapData() {
        const data:IAlert = {
            message: this.message,
            type: this.type,
            envName: this.envName,
            entityType: this.entityType,
            timestamp: this.timestamp
        }

        if (this.stack) data.stack = this.stack
        if (this.user) data.user = this.user
        if (this.entityId) data.entityId = this.entityId
        return data
    }
}

export default AbsAlert