import { Column, Options, QueryResult as MaterialTableQueryResult } from 'material-table'
import React from 'react'
import MultiPersonalInfoRow from '../Components/MultiPersonalInfoRow'
import PersonalInfoRow from '../Components/PersonalInfoRow'
import { MOBILE_INNER_WIDTH_THRESHOLD } from '../Constants/Misc'
import Doc from '../Models/Doc'
import ErrorManager from '../Models/ErrorManager'
import Firebase from '../Models/Firebase'
import Occurrence from '../Models/Occurrence'
import User from '../Models/User'
import { OccurrenceAction, OccurrenceEntity } from '../Shared/Occurrence'

class CommonTable {
    static dataEmpty<T extends object>() {
        return {
            data: [],
            page: 0,
            totalCount: 0
        } as MaterialTableQueryResult<T>
    }

    static async deleteDocument(firebase:Firebase, doc:Doc, currentUser:User, onQueryChange: Function, showMessage:(message: string) => void, t:Function) {
        try {
            if (!window.confirm(t('Do you really want to delete "{{title}}" document?', {title: doc.title}))) return
            doc.deleted = true
            await doc.save(firebase)
            const occurrence = new Occurrence({
                action: OccurrenceAction.DELETED,
                entity: OccurrenceEntity.DOCUMENT,
                entityId: doc.id,
                actor: currentUser.email,
                target: doc.title
            })
    
            await occurrence.save(firebase)
            onQueryChange()
            showMessage( t('document deleted') )
        } catch (e) {
            ErrorManager.query(firebase, e, showMessage)
        }
    }

    static getLocalization(t:Function) {
        return {
            header: {
                actions: t('Actions')
            },
            body: {
                emptyDataSourceMessage: t('No data to display')
            },
            pagination: {
                labelDisplayedRows: t('{from}-{to} of {count}'),
                labelRowsSelect: t('rows'),
                labelRowsPerPage: t('Rows per page:'),
                firstAriaLabel: t('First Page'),
                firstTooltip: t('First Page'),
                previousAriaLabel: t('Previous Page'),
                previousTooltip: t('Previous Page'),
                nextAriaLabel: t('Next Page'),
                nextTooltip: t('Next Page'),
                lastAriaLabel: t('Last Page'),
                lastTooltip: t('Last Page')
            }
        }
    }

    static renderDocRecipients(doc:Doc, short = false) {
        if (doc.recipientEmails.length === 0) return null
        if (doc.recipientEmails.length === 1) return (<PersonalInfoRow user={ doc.recipientsPersonalInfo[0] } short={ short } />)

        return (<MultiPersonalInfoRow users={ doc.recipientsPersonalInfo } />)
    }

    static getColumn(table:string, name:string, t:Function) {
        const mobile = window.innerWidth < MOBILE_INNER_WIDTH_THRESHOLD
        switch (table) {
            case 'documents':
                
                switch (name) {
                    case 'sender':
                        return { 
                            title: t('Sender'),
                            field: 'senderPersonalInfo.displayName',
                            sorting: false,
                            render: (rowData) => (<PersonalInfoRow user={ rowData.senderPersonalInfo! } short={ mobile } />)
                        } as Column<Doc>
                    case 'recipientEmails':
                        return { 
                            title: t('Recipients'),
                            field: 'recipientEmails',
                            sorting: false,
                            render: (rowData) => this.renderDocRecipients(rowData, mobile)
                        } as Column<Doc>
                }

                break
            default:
                throw new Error(`unrecognized "${table}" table in getColumn`)
        }
        throw new Error(`unrecognized "${name}" column for "${table}" table in getColumn`)
    }

    static async retrieveDocPersonalInfo(firebase:Firebase, data:Doc[], basedOn='users') {
        for (const doc of data) {
            await doc.retrievePersonalInfo(firebase, basedOn)
        }
    }

    static getOptions(type = 'documents') {
        function styleDeletedElement(rowData: {deleted: boolean}) {
            const style:any = {}
            if (rowData.deleted) {
                style.color = 'gray'
                style.backgroundColor = 'silver'
            }
            return style
        }

        const options:Options = {
            showTitle: false,
            thirdSortClick: false,
            search: false,
            actionsColumnIndex: -1,
            pageSize: 15,
            pageSizeOptions: [10, 15, 30]
        }
        switch (type) {
            case 'documents-admin':
                options.rowStyle = styleDeletedElement
        }

        return options
    }
}

export default CommonTable