import MomentUtils from '@date-io/moment'
import { Box, TextField } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import React, { forwardRef, useImperativeHandle } from "react"
import { useTranslation } from "react-i18next"
import { RecipientRowData } from "../Components/RecipientRow"
import RecipientsEdit from "../Components/RecipientsEdit"
import Contact from "../Models/Contact"
import Doc from "../Models/Doc"
import { FirebaseContext } from "../Models/Firebase"
import { UserContext } from "../Models/User"
import { storiesRecipientEmail, storiesTitlePrefix } from '../Shared/Constants'
import { DocType, IDoc } from "../Shared/Doc"
import { DataStatus, ISOToday } from "../Shared/Misc"
import { ProcedureType } from "../Shared/Procedure"
import Procedure from '../Models/Procedure'

export interface DocumentEditProps {
    dataDoc: IDoc,
    hide?:boolean,
    onValidityChanged?: (isValid: boolean) => void
    test?: boolean
}

function getRecipientRowDataByEmail(doc:Doc, email:string):RecipientRowData {
    const saveContact = ([DocType.PAYCHECK, DocType.UNIQUE_CERTIFICATION].indexOf(doc.type) !== -1)
    let procedureType = undefined as ProcedureType | undefined

    switch (doc.type) {
        case DocType.PAYCHECK:
            procedureType = ProcedureType.NOTIFICATION_DOCUMENT_UPLOADED
            break
        case DocType.UNIQUE_CERTIFICATION:
            procedureType = ProcedureType.ACKNOWLEDGEMENT
            break
    }

    return {
        recipientEmail: email,
        saveContact: saveContact,
        procedureType: procedureType
    }
}

const DocumentEditBase = function({ dataDoc, onValidityChanged, hide = false, test = false }:DocumentEditProps, ref: any) {
    const { t } = useTranslation()
    const firebase = React.useContext(FirebaseContext)
    const currentUser = React.useContext(UserContext).currentUser
    const [data, setData] = React.useState({
        doc: new Doc({
            ...dataDoc,
            date: dataDoc.date ? dataDoc.date : ISOToday()
        }),
        titleError: '',
        dateError: '',
        fiscalcodeError: '',
        status: DataStatus.LOADING,
        recipients: [] as RecipientRowData[],
        yearStr: dataDoc.year ? dataDoc.year : '',
        yearStrError: '',
        saved: false,
        isValid: false
    })

    async function save() {
        if (!firebase) throw new Error('no firebase')
        if (data.saved) throw new Error('already saved')
        if (!data.isValid) throw new Error('impossible to save when is not valid')
        let fiscalcode = undefined as string | undefined
        if ("fiscalcode" in data.doc) fiscalcode = data.doc.fiscalcode
        
        const contactsToSave = data.recipients.filter( recipient => recipient.saveContact )
            .map( recipient => new Contact({ 
                email: recipient.recipientEmail,
                owner: currentUser.id as string,
                fiscalcode: fiscalcode
            })
        )

        await data.doc.save(firebase)

        await Promise.all(
            data.recipients.map( async recipient => {
                if (recipient.procedureType) {
                    const procedure = new Procedure({
                        issuer: currentUser.id as string,
                        recipientEmail: recipient.recipientEmail,
                        type: recipient.procedureType,
                        handler: data.doc.id as string
                    })
                    await procedure.save(firebase)
                }
            })
        )

        await Promise.all(contactsToSave.map( contact => Contact.upsertContact(firebase, contact) ))
        setData({
            ...data,
            doc: data.doc,
            saved: true
        })

        return data.doc.id as string
    }   

    useImperativeHandle(ref, () => {
        return {
          save: () => save()
        }
    })
    
    async function loader() {
        try {
            if (!firebase) throw new Error('no firebase')
            setData({
                ...data,
                status: DataStatus.EMPTY
            })
            //@ts-ignore
            if (!data.doc.title) data.doc.title = data.doc.getTitle(t)
            if (test) data.doc.title = storiesTitlePrefix + ' ' + data.doc.title
            if (!data.doc.date) data.doc.date = ISOToday()
            let recipientEmails = data.doc.recipientEmails
            let firstRecipientEmail = ''

            if ( recipientEmails.length === 0 ) {
                if (data.doc.type !== DocType.OTHER && data.doc.fiscalcode) {
                    // @ts-ignore
                    const fiscalcode = data.doc.fiscalcode
                    const contactSnap = await firebase.db.collection('contacts')
                        .where('owner', '==', currentUser.id)
                        .where('fiscalcode', '==', fiscalcode)
                        .limit(1).get()
                    if (!contactSnap.empty)
                        //@ts-ignore
                        firstRecipientEmail = contactSnap.docs[0].data().email    
                }
                if (test && !firstRecipientEmail) firstRecipientEmail = storiesRecipientEmail
                recipientEmails.push(firstRecipientEmail)
            }

            const recipients = recipientEmails.map( (email:string) => getRecipientRowDataByEmail(data.doc, email) )
            const isValid = (data.doc.getErrors().length === 0)

            if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
            setData({
                ...data,
                doc: data.doc,
                isValid: isValid,
                recipients: recipients,
                status: DataStatus.READY
            })
        } catch (e) {
            console.error(e)
        }
    }

    function titleChanged(e: { target: { value: string } }) {
        const title = e.target.value
        data.doc.title = title

        const isValid = (data.doc.getErrors().length === 0)
        if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
        setData({
            ...data,
            doc: data.doc,
            titleError: title ? '' : t('title is required'),
            isValid: isValid
        })
    }

    function fiscalcodeChanged(e: { target: { value: string } }) {
        const fiscalcode = e.target.value
        // @ts-ignore
        data.doc.fiscalcode = fiscalcode
        // @ts-ignore
        const fiscalcodeError = data.doc.getFiscalcodeError(t)

        const isValid = !fiscalcodeError  && (data.doc.getErrors().length === 0)
        if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
        setData({
            ...data,
            doc: data.doc,
            fiscalcodeError: fiscalcodeError,
            isValid: isValid
        })
    }

    function yearChanged(e: { target: { value: string } }) {
        const newValueStr = e.target.value
        const newValue = parseInt(newValueStr)
        let error = ''
        if (!isNaN(newValue)) {
            data.doc.year = newValue
            error = data.doc.getYearError(t)
        } else error = t('invalid number')

        const isValid = (data.doc.getErrors().length === 0)
        if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
        setData({
            ...data,
            doc: data.doc,
            yearStr: newValueStr,
            yearStrError: error,
            isValid: isValid
        })
    }

    function dateChanged(moment: any, value: string | null | undefined) {
        const dateValid = moment && moment.isValid()
        data.doc.date = value ? value : ''
        let dateError = !value || dateValid  ? '' : t('invalid date')
        if (data.doc.date === '') dateError = t('date is required')

        const isValid = (data.doc.getErrors().length === 0)
        if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
        setData({
            ...data,
            doc: data.doc,
            dateError: dateError,
            isValid: isValid
        })
    }

    function recipientEmailChanged(pos:number, email:string) {
        data.recipients[pos].recipientEmail = email
        data.doc.recipientEmails[pos] = email
        const isValid = (data.doc.getErrors().length === 0)
        if (isValid !== data.isValid && onValidityChanged) onValidityChanged(isValid)
        setData({
            ...data,
            doc: data.doc,
            recipients: data.recipients,
            isValid: isValid
        })
    }

    function recipientProcedureTypeChanged(pos:number, procedureType?:ProcedureType) {
        data.recipients[pos].procedureType = procedureType
        setData({
            ...data,
            recipients: data.recipients
        })
    }

    function recipientSaveContactChanged(pos:number, saveContact:boolean) {
        data.recipients[pos].saveContact = saveContact
        setData({
            ...data,
            recipients: data.recipients
        })
    }

    React.useEffect(() => {
        if (data.status === DataStatus.LOADING && firebase) loader()
    })

    if (data.saved || hide) return null

    return (
        <Box m={2}>
            <TextField
                fullWidth
                error={ !!data.titleError }
                helperText={ data.titleError }
                label={ t('Title')}
                onChange={ titleChanged }
                value={ data.doc.title }
            />

            {
                (data.doc.type !== DocType.OTHER) ? (
                    <React.Fragment>
                        <TextField
                            fullWidth
                            error={ !!data.fiscalcodeError }
                            helperText={ data.fiscalcodeError }
                            label={ t('Fiscalcode')}
                            onChange={ fiscalcodeChanged }
                            // @ts-ignore
                            value={ data.doc.fiscalcode ? data.doc.fiscalcode : '' }
                        />

                        <TextField
                            fullWidth
                            error={ !!data.yearStrError }
                            helperText={ data.yearStrError }
                            label={ t('Year')}
                            onChange={ yearChanged }
                            // @ts-ignore
                            value={ data.yearStr }
                            inputProps={{
                                type: 'number'
                            }}
                        />
                    </React.Fragment>


                ) : null
            }

            <MuiPickersUtilsProvider utils={ MomentUtils }>
                <KeyboardDatePicker
                    disableToolbar
                    error={ !!data.dateError }
                    helperText={ data.dateError }
                    variant="inline"
                    format="YYYY-MM-DD"
                    margin="normal"
                    label={ t('Date') }
                    value={ data.doc.date }
                    onChange={ dateChanged }
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </MuiPickersUtilsProvider>

            <RecipientsEdit 
                recipients={ data.recipients }
                onEmailChanged={ recipientEmailChanged }
                onProcedureTypeChanged={ recipientProcedureTypeChanged }
                onSaveContactChanged={ recipientSaveContactChanged }
                variableNumOfElements={ false }
                maxElements={ 1 }
            />
        </Box>
    )
}

const DocumentEdit = forwardRef(DocumentEditBase)
export default DocumentEdit