import React, { useContext, useImperativeHandle, forwardRef, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { Button } from '@material-ui/core'
import Login from '../Dialogs/Login'
import SideMenu from './SideMenu'
import UserBadge from './UserBadge'
import { Trans } from 'react-i18next'
import { UserContext, UserStatus } from '../Models/User'
import { MOBILE_INNER_WIDTH_THRESHOLD } from '../Constants/Misc'
import { DASHBOARD, isLoggedLocation } from '../Constants/Routes'
import { useHistory, useLocation } from 'react-router-dom'
import PrivacyPolicy from '../Dialogs/PrivacyPolicy'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Trans i18nKey='footerMessage'>
        {"Payload is a "}
        <Link
          color="inherit"
          href="https://wink.by/"
          target="_blank"
          rel="noreferrer"
        >WiNK</Link>{" "}
        {" product."}
      </Trans>
    </Typography>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}))

const NavBarUserInfo:React.SFC<{ openModalLogin: Function }> = ({ openModalLogin }) => {
  const currentUser = useContext(UserContext).currentUser

  switch(currentUser.getStatus()) {
    case UserStatus.UNKNOWN: return null
    case UserStatus.LOGGED: return (<UserBadge />)
    case UserStatus.ANONYMOUS: return (<Button variant="contained" onClick={() => openModalLogin(true)} color="secondary"><Trans>Login</Trans></Button>)
  }
}

export interface LayoutProps {
  title?: string
}

const LayoutBase = function(props: LayoutProps & { children: React.ReactNode }, ref: any) {
  const title = props.title ? props.title : 'Payload'
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const currentUser = React.useContext(UserContext).currentUser
  const prevCurrentUser = React.useRef(currentUser.getStatus())
  const privacyPolicyRef = React.useRef()
  const [status, setStatus] = React.useState({
    drawerOpened: window.innerWidth > MOBILE_INNER_WIDTH_THRESHOLD,
    loginDialogOpened: false
  })

  function handleDrawerOpening (open:boolean) {
    setStatus({
      ...status,
      drawerOpened: open
    })
  }

  function handleLoginDialogOpening (open:boolean) {
    setStatus({
      ...status,
      loginDialogOpened: open
    })
  }

  useImperativeHandle(ref, () => {
      return {
        handleLoginDialogOpening: (open: boolean) => handleLoginDialogOpening(open)
      }
  })

  React.useEffect(() => {
    if (currentUser.getStatus() === UserStatus.LOGGED) {
      if (prevCurrentUser.current === UserStatus.ANONYMOUS) {
        if (isLoggedLocation(location.pathname)) handleLoginDialogOpening(false)
          else history.push(DASHBOARD)
      } else if (!currentUser.privacyPolicySigned)
        //@ts-ignore
        privacyPolicyRef.current.open()
    }

    prevCurrentUser.current = currentUser.getStatus()  
  })
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, status.drawerOpened && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={ () => handleDrawerOpening(true) }
            className={clsx(classes.menuButton, status.drawerOpened && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            { title }
          </Typography>
          <NavBarUserInfo openModalLogin={ () => setStatus({
            ...status,
            loginDialogOpened: true
          }) } />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !status.drawerOpened && classes.drawerPaperClose),
        }}
        open={ !status.drawerOpened }
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={ () => handleDrawerOpening(false) }>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List><SideMenu /></List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <PrivacyPolicy ref={ privacyPolicyRef } />
          <Login opened={ status.loginDialogOpened } onClose={ () => handleLoginDialogOpening(false) }  />
          {props.children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export const LayoutLoginCtrl = forwardRef(LayoutBase)
const Layout = ({children, ...others}: LayoutProps & { children: React.ReactNode }) => {
  const layoutRef = useRef()
  return (<LayoutLoginCtrl { ...others } ref={ layoutRef }>{ children }</LayoutLoginCtrl>)
}


export default Layout