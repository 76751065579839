import React from 'react'
//import Group, { GroupRole } from './Group'
import Firebase, { FirebaseApp } from './Firebase'
import AbsUser, { IUser, IUserDb, CustomClaims, IUserFirebaseAuth } from '../Shared/User'
import CommonFirebase from './CommonFirebase'
import _ from 'lodash'
import { platformLanguages, defaultLanguage } from '../Shared/Constants'
import Occurrence from './Occurrence'
import { OccurrenceAction, OccurrenceEntity } from '../Shared/Occurrence'

export enum UserStatus {
    ANONYMOUS = 'anonymous',
    LOGGED = 'logged',
    UNKNOWN = 'unknown'
}

export interface InternalUser extends IUser {
    status: UserStatus
    customClaimsLoaded: boolean
}

export interface GetByOptions {
    saveInSessionStorage?: boolean,
    forceFromNetwork?: boolean
}

export interface BrowserResult {
    language: string
}

type DocumentSnapshot = FirebaseApp.firestore.DocumentSnapshot<FirebaseApp.firestore.DocumentData>

export default class User extends AbsUser implements IUser {
    private status = UserStatus.UNKNOWN
//    private hasGroupsCached = false
    private customClaimsLoaded = false

    constructor(data?:IUser) {
        super({
            ...data,
            email: data ? data.email : ''
        })
        if (data && data.id) this.status = UserStatus.LOGGED
    }

    setAnonymous() { this.status = UserStatus.ANONYMOUS }
    getStatus() { return this.status }
    setId(id:string) { 
        this.id = id
        this.status = UserStatus.LOGGED
    }

    async save(firebase:Firebase) {
        await CommonFirebase.save(firebase, this, 'users')
    }

    getData() {
        let data = super.getData() as InternalUser
        data.customClaimsLoaded = this.customClaimsLoaded
        data.status = this.status
        return data
    }

    async savePrivacyPolicySigned(firebase:Firebase) {
        const currentUser = firebase.app.auth().currentUser
        if (!currentUser) throw new Error('no current user')

        this.privacyPolicySigned = true
        const occurrence = new Occurrence({
            action: OccurrenceAction.PRIVACY_POLICY_ACCEPTED,
            entity: OccurrenceEntity.USER,
            actor: currentUser.email as string
        })
        await this.save(firebase)
        await occurrence.save(firebase)
    }

    updateByCustomClaims(customClaims:CustomClaims) {
        super.updateByCustomClaims(customClaims)
        this.customClaimsLoaded = true
    }

    async loadCustomClaims(firebase:Firebase) {
        const getUserCustomClaims = firebase.app.functions('europe-west1').httpsCallable('getUserCustomClaims')
        if (!this.id) throw new Error('unable to get custom claims with no id')
        
        const customClaims = (await getUserCustomClaims(this.id)).data
        this.updateByCustomClaims(customClaims)
    }

    static fromData(data:InternalUser) {
        let user = new User(data)
        if (data.status === UserStatus.ANONYMOUS) user.setAnonymous()
        user.customClaimsLoaded = data.customClaimsLoaded
        return user
    }

    static fromSnap(snap:DocumentSnapshot) {
        let doc = new User(snap.data() as IUserDb)
        doc.setId(snap.id)
        return doc
    }

    static fromFirebaseAuth(data:IUserFirebaseAuth) {
        return new User(data)
    }

    static async getById(firebase:Firebase, id:string, inputOptions?:GetByOptions) {
        let usersData = [] as InternalUser[]
        const options = _.defaults(inputOptions, {
            saveInSessionStorage: true,
            forceFromNetwork: false
        })

        if (!options.forceFromNetwork) {
            let usersStoredStr = window.sessionStorage.getItem('users')
            if (usersStoredStr) {
                usersData = JSON.parse(usersStoredStr)
                for (let i in usersData) if (usersData[i].id === id) return this.fromData(usersData[i])
            }
        }

        const collection = firebase.db.collection('users')
        try {
            const snap = await collection.doc(id).get()
            if (!snap.exists) return null
            const user = this.fromSnap(snap)
            if (options.saveInSessionStorage) {
                usersData.push(user.getData())
                window.sessionStorage.setItem('users', JSON.stringify(usersData))
            }
            return user
        } catch (e) {
            if (e.message.indexOf('Missing or insufficient permissions') !== -1) return null
                else throw e
        }
    }

    static async getByEmail(firebase:Firebase, email:string, inputOptions?:GetByOptions) {
        let usersData = [] as InternalUser[]
        const options = _.defaults(inputOptions, {
            saveInSessionStorage: true,
            forceFromNetwork: false
        })

        if (!options.forceFromNetwork) {
                let usersStoredStr = window.sessionStorage.getItem('users')
            if (usersStoredStr) {
                usersData = JSON.parse(usersStoredStr)
                for (let i in usersData) if (usersData[i].email === email) return this.fromData(usersData[i])
            }
        }

        const collection = firebase.db.collection('users')
        const snap = await collection.where('email', '==', email).get()
        if (snap.empty) return null
        const user = this.fromSnap(snap.docs[0])
        if (options.saveInSessionStorage) {
            usersData.push(user.getData())
            window.sessionStorage.setItem('users', JSON.stringify(usersData))
        }
        return user
    }

    static detectBrowser() {
        // @ts-ignore
        let language = window.navigator.userLanguage || window.navigator.language

        if (platformLanguages.indexOf(language) === -1) language = defaultLanguage
        return {
            language: language
        } as BrowserResult
    }
}

export const UserContext = React.createContext({ currentUser: new User(), updateCurrentUser: (user:User) => {}  })