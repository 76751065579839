import React, { useState, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Firebase, { FirebaseContext } from './Models/Firebase'
import User, { UserContext } from './Models/User'
import * as ROUTES from './Constants/Routes'

import Landing from './Pages/Landing'
import Features from './Pages/Features'
import Pricing from './Pages/Pricing'
import Dashboard from './Pages/Dashboard'
import Documents from './Pages/Documents'
//import Groups from './Pages/Groups'
import Users from './Pages/Users'
import UserPage from './Pages/User'
//import Group from './Pages/Group'
//import GroupAdminList from './Pages/GroupAdminList'
import Document from './Pages/Document'
import { createMuiTheme, ThemeProvider, CircularProgress } from '@material-ui/core'

const Contacts = lazy(() => import('./Pages/Contacts'))
const Privacy = lazy(() => import('./Pages/Privacy'))

const firebase = new Firebase()

const theme = createMuiTheme({
  palette: {
    primary: { main: '#006064' },
    secondary: { main: '#90a4ae' },
  },
  typography: {
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
      fontWeight: 500
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      fontWeight: 500
    },
    h5: {
      fontSize: "1.1rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      fontWeight: 500
    }
  }
})

function App() {
    const [currentUser, setCurrentUser] = useState(new User())

    const onAuthStateChanged = async function() {
        const user = await firebase.retrieveCurrentUser()
        setCurrentUser(user)
    }

    if (!firebase.hasOnAuthStateChanged())
        firebase.setOnAuthStateChangedCallback(onAuthStateChanged)

  return (
    <FirebaseContext.Provider value={ firebase }>
      <UserContext.Provider value={ {currentUser: currentUser, updateCurrentUser: setCurrentUser} }>
        <ThemeProvider theme={ theme }>
          <Router>
            <Suspense fallback={ <CircularProgress /> }>
              <Route exact path={ROUTES.LANDING} component={Landing} />
              <Route path={ROUTES.FEATURES} component={Features} />
              <Route path={ROUTES.PRICING} component={Pricing} />
              <Route path={ROUTES.DOCUMENT} component={ Document } />
              <Route path={ROUTES.PRIVACY} component={Privacy} />

              <Route path={ROUTES.DASHBOARD} component={Dashboard} />
              <Route path={ROUTES.DOCUMENTS} component={Documents} />
              <Route path={ROUTES.CONTACTS} component={Contacts} />
              {/*
                <Route path={ROUTES.GROUPS} component={Groups} />
                <Route path={ROUTES.GROUPADMINLIST} component={GroupAdminList} />
                <Route path={ROUTES.GROUP} component={Group} />
              */}
              <Route path={ROUTES.USERS} component={Users} />
              <Route path={ROUTES.USER} component={UserPage} />
            </Suspense>
          </Router>
        </ThemeProvider>
      </UserContext.Provider>
    </FirebaseContext.Provider>
  )
}

export default App