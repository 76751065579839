import { Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import TemplateEditableList from "../Cards/TemplateEditableList"
import Layout from '../Components/Layout'
import { DOCUMENTS } from "../Constants/Routes"
import { FirebaseContext } from "../Models/Firebase"
import { TemplateEntityTypes } from "../Shared/Template"
import { UserContext, UserStatus } from "../Models/User"
import Alerts from "../Cards/Alerts"
import BackofficeActions from "../Cards/BackofficeActions"

const Dashboard = () => {
    const history = useHistory()
    const currentUser = React.useContext(UserContext).currentUser
    const firebase = React.useContext(FirebaseContext)
    const { t } = useTranslation()

    if (currentUser.getStatus() !== UserStatus.LOGGED || !firebase) return null
    if (!currentUser.isAdmin) {
        history.push(DOCUMENTS)
        return null
    }

    return (
        <Layout title={ t('Dashboard') }>
            <Grid container spacing={ 2 } alignContent="space-between">
                <Grid item xs={ 12 } md={ 4 }>
                    <Alerts />
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                    <BackofficeActions />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                    <TemplateEditableList entityType={ TemplateEntityTypes.SYSTEM } />
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Dashboard