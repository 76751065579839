import { Popover } from "@material-ui/core"
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MaterialTable, { Options, Query } from "material-table"
import React from "react"
import { useTranslation } from "react-i18next"
import DocumentsTableMenu from "../Components/DocumentsTableMenu"
import ErrorSnackbar from "../Components/ErrorSnackbar"
import DocumentHistory from "../Dialogs/DocumentHistory"
import Doc from '../Models/Doc'
import ErrorManager from "../Models/ErrorManager"
import Firebase, { FirebaseApp, FirebaseContext } from "../Models/Firebase"
import { UserContext, UserStatus } from "../Models/User"
import CommonTable from "./CommonTable"

const DocumentsSent:React.SFC<{ onUploadFile:(uploadingFile: boolean) => void }> = ({ onUploadFile }) => {
    const { t } = useTranslation()
    const firebase = React.useContext(FirebaseContext)
    const currentUser = React.useContext(UserContext).currentUser
    const tableRef = React.useRef()
    const historyDialogRef = React.useRef()
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    let lastSnapshot: FirebaseApp.firestore.QuerySnapshot<FirebaseApp.firestore.DocumentData> | undefined
    let currentPage = 0
    const [data, setData] = React.useState({
        currentDoc: null as Doc | null,
        rowEl: null as HTMLButtonElement | null
    })

    async function retrieveData(query:Query<Doc>) {
        try {
            if (!firebase) throw new Error('no firebase')
            
            const result = await firebase.retrieveDataForMaterialTable<Doc>(
                firebase.db.collection('documents').where('deleted', '==', false).where('sender', '==', currentUser.id),
                query,
                Doc.fromSnap,
                currentPage,
                lastSnapshot
            )
            lastSnapshot = result.snapshot
            currentPage = result.materialTableData.page
            return result.materialTableData
        } catch(e) {
            // @ts-ignore
            ErrorManager.query(firebase, e, errorSnackbarRef.current.open)
            return CommonTable.dataEmpty<Doc>()
        }
    }

    if (!firebase || currentUser.getStatus() !== UserStatus.LOGGED) return null

    function openHistoryDialog() {
        setData({ ...data, rowEl: null })
        // @ts-ignore
        historyDialogRef.current.open(data.currentDoc)
    }

    async function deleteDocument() {
        setData({ ...data, rowEl: null })
        await CommonTable.deleteDocument(
            firebase!,
            data.currentDoc!,
            currentUser!,
            // @ts-ignore
            tableRef.current.onQueryChange,
            // @ts-ignore
            errorSnackbarRef.current.open,
            t
        )
    }
    
    async function eraseDocument() {
        setData({ ...data, rowEl: null })
        const doc = data.currentDoc as Doc
        await doc.delete(firebase as Firebase)
        //@ts-ignore
        tableRef.current.onQueryChange()
    }

    const tableOptions:Options = {
        showTitle: false,
        thirdSortClick: false,
        search: false,
        actionsColumnIndex: -1,
        pageSize: 15,
        pageSizeOptions: [10, 15, 30]
    }

    const tableLocalization = CommonTable.getLocalization(t)

    async function rowAction(doc: Doc) {
        // @ts-ignore
        await doc.open(firebase, errorSnackbarRef.current.open)
    }

    const addAction = {
        icon: 'add',
        isFreeAction: true,
        tooltip: t('Upload a new file'),
        onClick: () => onUploadFile(true)
    }

    const menuAction = {
        icon: () => (
            <React.Fragment>
                <MoreVertIcon />
            </React.Fragment>
        ),
        tooltip: t('Menu'),
        onClick: (e:React.MouseEvent<HTMLButtonElement>, rowData:any) => setData({
            ...data,
            rowEl: e.currentTarget,
            currentDoc: rowData
        })
    }

    const actions = (currentUser.email === 'domenico.pontari@gmail.com' || currentUser.email === 'payload@wink.by') ? [addAction, menuAction] : [menuAction]

    return (
        <React.Fragment>
            <ErrorSnackbar ref={ errorSnackbarRef } />
            <DocumentHistory 
                ref={ historyDialogRef }
            />
            <Popover
                anchorEl={ data.rowEl }
                open={ data.rowEl ? true : false }
                onClose={ () => setData({...data, rowEl: null}) }
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <DocumentsTableMenu 
                    onClickHistory={ openHistoryDialog }
                    onClickDelete={ deleteDocument }
                    onClickErase={ eraseDocument }
                />
            </Popover>
            <MaterialTable
                actions={ actions }
                tableRef={ tableRef }
                options = { tableOptions }
                localization = { tableLocalization }
                onRowClick={(event, rowData) => rowData ? rowAction(rowData) : null}
                columns={[
                    { title: t('Alerts'), field: 'numOfProcedures', render: rowData => rowData.renderAlertsInTable('sent') },
                    { title: t('Date'), field: 'date', defaultSort: 'desc' },
                    { title: t('Title'), field: 'title' },
                    { title: t('Recipients'), field: 'recipientEmails', sorting: false }
                ]}
                data={ retrieveData }
            />
        </React.Fragment>
    )
}

export default DocumentsSent