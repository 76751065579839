import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
//import GroupsIcon from '@material-ui/icons/Group'
import ContactsIcon from '@material-ui/icons/Contacts'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DescriptionIcon from '@material-ui/icons/Description'
import Logout from '@material-ui/icons/ExitToApp'
import UsersIcon from '@material-ui/icons/Person'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import * as ROUTES from '../Constants/Routes'
import { NO_FIREBASE } from '../Models/ErrorManager'
import { FirebaseContext } from '../Models/Firebase'
import { UserContext } from '../Models/User'
import "./SideMenu.css"

const SideMenu = () => {
    const { t } = useTranslation()
    const firebase = React.useContext(FirebaseContext)
    const history = useHistory()

    let userContext = React.useContext(UserContext)
    let updateCurrentUser = userContext.updateCurrentUser
    let currentUser = userContext.currentUser

    async function logout() {
        try {
            if (!firebase) throw new Error(NO_FIREBASE)
            firebase.logout(updateCurrentUser, history)
        } catch (e) {
            console.error(e)
        }
    }
    
    return (
        <div>
            { currentUser.isAdmin ? (
            <NavLink 
                to={ROUTES.DASHBOARD} 
                style={{ textDecoration: 'none', color: "inherit" }}
                activeClassName="active"
            >
                <ListItem button>
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText  primary={t("Dashboard")} />
                </ListItem>
            </NavLink>
            ) : null }

            <NavLink 
                to={ROUTES.DOCUMENTS}
                style={{ textDecoration: 'none', color: "inherit" }}
                activeClassName="active"
            >
                <ListItem button>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary={t("Documents")} />
                </ListItem>
            </NavLink>

            <NavLink 
                to={ROUTES.CONTACTS}
                style={{ textDecoration: 'none', color: "inherit" }}
                activeClassName="active"
            >
                <ListItem button>
                    <ListItemIcon><ContactsIcon /></ListItemIcon>
                    <ListItemText primary={t("Contacts")} />
                </ListItem>
            </NavLink>

            { /* <NavLink 
                to={ROUTES.GROUPS}
                style={{ textDecoration: 'none', color: "inherit" }}
                activeClassName="active"
            >
                <ListItem button>
                    <ListItemIcon><GroupsIcon /></ListItemIcon>
                    <ListItemText primary={t("Groups")} />
                </ListItem>
            </NavLink> 
            
                        { currentUser.isAdmin ? (
                
                <React.Fragment>
                    <NavLink 
                        to={ROUTES.GROUPADMINLIST}
                        style={{ textDecoration: 'none', color: "inherit" }}
                        activeClassName="active"
                    >
                        <ListItem button>
                            <ListItemIcon><GroupsIcon /></ListItemIcon>
                            <ListItemText primary={t("Groups Admin")} />
                        </ListItem>
                    </NavLink>

                    <NavLink 
                        to={ROUTES.USERS}
                        style={{ textDecoration: 'none', color: "inherit" }}
                        activeClassName="active"
                    >
                        <ListItem button>
                            <ListItemIcon><UsersIcon /></ListItemIcon>
                            <ListItemText primary={t("Users")} />
                        </ListItem>
                    </NavLink>
                </React.Fragment>

            ) : null }
            
            
            */ }

            <NavLink 
                to={ROUTES.USER + '/' + currentUser.id}
                style={{ textDecoration: 'none', color: "inherit" }}
                activeClassName="active"
            >
                <ListItem button>
                    <ListItemIcon><UsersIcon /></ListItemIcon>
                    <ListItemText primary={t("Profile")} />
                </ListItem>
            </NavLink>
            
            <ListItem button onClick={ logout }>
                <ListItemIcon><Logout /></ListItemIcon>
                <ListItemText primary={t("Logout")} />
            </ListItem>
        </div>
    )
}

export default SideMenu