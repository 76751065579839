import _ from "lodash"

export enum DataStatus {
    LOADING = 'loading',
    EMPTY = 'empty',
    READY = 'ready',
    ACCESS_DENIED = 'access denied'
}

export const italianMonths:string[] = ['GENNAIO', 'FEBBRAIO', 'MARZO', 'APRILE', 'MAGGIO', 'GIUGNO', 'LUGLIO', 'AGOSTO', 'SETTEMBRE', 'OTTOBRE', 'NOVEMBRE', 'DICEMBRE']

export async function runPromises<T>(collection:Array<T>, asyncFunction:Function, concurrency = 10) {
    const pieces:T[][] = []
    let endReached = false
    let currIndex = 0

    while (!endReached) {
        const start = currIndex * concurrency
        let end: number
        if (collection.length <= start + concurrency) {
            endReached = true
            end = collection.length
        } else end = start + concurrency
        pieces[currIndex++] = collection.slice(start, end)
    }

    for (const i in pieces) {
        await Promise.all( pieces[i].map( el => asyncFunction(el) ) )
    }
}

export function getIntervalsBasedOnToken(pages: string[], token: string, head = true) {
    const result = []
    let last = -1
    for (let i = 0; i < pages.length; i++) {
        if (pages[i].indexOf(token) !== -1) {
            if (head && last === -1) {
                last = i
                continue
            }
            const el = {
                start: last,
                end: i
            }
            if (head) --el.end; else ++el.start
            last = i
            result.push(el)
        }
    }

    if (head) result.push({
        start: last,
        end: pages.length - 1
    })

    return result
}

export function padZeros(value:number|string, dim:number):string {
    let result:string
    if (typeof value !== 'string') result = value.toString(); else result = value
    dim -= result.length
    for (let i = 0; i < dim; i++) result = '0' + result
    return result
}

export function objectAttributes(obj:any, parent = '') {
    let list = [] as string[]

    for (const key in obj) {
        const value = obj[key]
        if (typeof value === 'object' && value !== null) list = list.concat( objectAttributes(value, key + '.') )
            else list.push(parent + key)
    }
    
    return list
}

export function ISOToday() {
    return (new Date()).toISOString().substring(0, 10)
}

export function combine(obj:any):any {
    const keys = Object.keys(obj)
    if (keys.length === 0) return []
    function objectizator(key:any, value:any) {
        const objEl = {}
        // @ts-ignore
        objEl[key] = value
        return objEl
    }

    const key0 = keys[0]
    const firstRow = obj[key0].map( (el:any) => typeof el === 'object' ? el : objectizator(key0, el) )
    if (keys.length === 1) return firstRow

    const key1 = keys[1]
    let result = [] as any[]
    for (const secondEl of obj[key1]) {
        result = result.concat( firstRow.map( (firstEl:any) => {
            const newEl = _.clone(firstEl)
            newEl[key1] = secondEl
            return newEl
        } )  )
    }
    if (keys.length === 2) return result
    const overResult = {'result': result}

    // @ts-ignore
    for (let i = 2; i < keys.length; i++) overResult[keys[i]] = obj[keys[i]]
    return combine(overResult)
}

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
        Object.defineProperty(
          derivedCtor.prototype,
          name,
          //@ts-ignore
          Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
        );
      });
    });
  }

export function emailError(email:string, isRequired:boolean, t?:Function):string {
    const identity = (err:string) => err
    if (!t) t = identity
    if (!email)
        return isRequired ? t('email is required') : ''
    
    const isValid = !!email.match(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    return !isValid ? t('invalid email') : ''
}