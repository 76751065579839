import React, { useContext } from "react"
//import Layout from '../Components/Layout'
import { FirebaseContext } from "../Models/Firebase"
import { UserContext, UserStatus } from "../Models/User"
import { AccessDenied } from "./MessageWithLayout"
import { useTranslation } from 'react-i18next'
//import getMessage from "../Models/ErrorManager"
//import MaterialTable from 'material-table'
//import { listUsersOptions } from '../../functions/src/lib/firebaseAdmin'
//import UserDetailPanel from "../Components/UserDetailPanel"
//import UserRow from "../Components/UserRow"

const Users = () => {
    const firebase = useContext(FirebaseContext)
    const currentUser = useContext(UserContext).currentUser
    const { t } = useTranslation()
    if (!firebase || currentUser.getStatus() !== UserStatus.LOGGED) return null

    if (!currentUser.isAdmin) return (<AccessDenied title={ t('Users') } />)

    return (
        <div>to be implemented</div>
        /*
        <Layout title={ t('Users') }>
            <MaterialTable
                title = ""
                columns={[
                    {
                        field: 'user',
                        sorting: false,
                        render: rowData => (<UserRow user={ User.createFromData(rowData) } />)
                    },
                ]}
                data={ retrieveData }
                onRowClick={() => alert('to do')}
        />
        </Layout>*/
        
    )
}

export default Users