import React, { forwardRef, useImperativeHandle } from "react"
import Doc from "../Models/Doc"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core"
import { Trans } from "react-i18next"

const DocumentHistoryBase = function(props:{}, ref: any) {
    const [data, setData] = React.useState({
        opened: false,
        doc: null as Doc | null
    })

    function open(doc:Doc) {
        setData({
            ...data,
            opened: true,
            doc: doc
        })
    }

    useImperativeHandle(ref, () => {
        return { open: open }
    })

    function onClose() {
        setData({
            ...data,
            opened: false
        })
    }

    return (
        <Dialog 
            open={ data.opened }
            onClose={ onClose }
            aria-labelledby="document-history-dialog-title"
        >
            <DialogTitle id="document-history-dialog-title">
                <Trans>Document Activities</Trans>
            </DialogTitle>
            <DialogContent>
                <Typography
                    display="block"
                    style={{fontSize:"9pt"}}
                    variant="subtitle2"
                    align="right"
                    color="textSecondary"
                    noWrap
                >id: { data.doc ? data.doc.id : '' }
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={ onClose } color="primary">
                    <Trans>Close</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const DocumentHistory = forwardRef(DocumentHistoryBase)
export default DocumentHistory