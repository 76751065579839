import React from "react"
import { Card, CardHeader, CardContent, FormControl, InputLabel, Select, MenuItem, Button, CardActions, TextField, Typography } from "@material-ui/core"
import { useTranslation, Trans } from "react-i18next"
import { DocType } from "../Shared/Doc"

export interface DocumentAddProps {
    maxPages: number,
    onAdd: (docType:DocType, from:number, to:number) => void
}

export default function DocumentAdd (props: DocumentAddProps) {
    const { t } = useTranslation()
    
    const [data, setData] = React.useState({
        docType: DocType.OTHER,
        from: "1",
        to: props.maxPages.toString(),
        errorInPages: ''
    })

    function changePages(isFromPage:boolean, newValueStr:string) {
        const newValue = parseInt(newValueStr)
        let error = ''
        const toValue = parseInt(data.to)
        let from = data.from
        let to = data.to
        if (isFromPage) from = newValueStr
            else to = newValueStr
        if (!isNaN(newValue)) {
            if (isFromPage) {
                if (newValue < 1) error = t('"from page" cannot be less than 1')
                if (newValue > toValue) error = t('"from page" cannot be greater than "to page"')
            } else {
                if (newValue > props.maxPages) error = t('"to page" cannot be greater than the number of pages in the file')    
            }
            
        } else {
            if (isFromPage) error = t('"from page" is not a number')
                else error = t('"to page" is not a number')
        }

        setData({
            ...data,
            from: from,
            to: to,
            errorInPages: error
        })
    }

    return (
        <Card>
            <CardHeader
                title={ t('Add document from file') }
            />
            <CardContent>
                {  data.errorInPages ? (<Typography variant="caption" style={{color:'red'}}>{data.errorInPages}</Typography>) : null }

                <FormControl fullWidth style={{margin: ".5em 0"}}>
                    <InputLabel shrink><Trans>Document Type</Trans></InputLabel>
                    <Select
                        fullWidth
                        value={ data.docType }
                        onChange={ (e) => {  setData({ ...data, docType: e.target.value as DocType }) }}
                    >
                        <MenuItem value={ DocType.OTHER }><Trans>generic</Trans></MenuItem>
                        <MenuItem value={ DocType.UNIQUE_CERTIFICATION }><Trans>unique certification</Trans></MenuItem>
                        <MenuItem value={ DocType.PAYCHECK }><Trans>paycheck</Trans></MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    label={ t('from page')}
                    onChange={ (e) => changePages(true, e.target.value) }
                    value={ data.from }
                />

                <TextField
                    fullWidth
                    label={ t('to page')}
                    onChange={ (e) => changePages(false, e.target.value) }
                    value={ data.to }
                />

            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={ () => props.onAdd(data.docType, parseInt(data.from), parseInt(data.to)) }>
                    <Trans>Add</Trans>
                </Button>
            </CardActions>
        </Card>

    )
} 