import i18n from '../i18n'
import { AlertEntityType, AlertType } from "../Shared/Alert"
import Alert from "./Alert"
import Firebase from "./Firebase"
import { IProcedureEntry } from '../Shared/Procedure'

export type displayMessage = (message:string) => void
export const NO_FIREBASE = 'no firebase'
export const USER_NOT_LOGGED = 'user not logged'

class ErrorManager {
    static noFirebase(e:Error, displayMessage?:displayMessage) {
        console.error(e)
        if (displayMessage) {
            const userMessage = i18n.t('system error (no Firebase)')
            displayMessage(userMessage)
        }
    }

    static async indexRequired(firebase:Firebase, e:Error, displayMessage?:displayMessage) {
        console.error(e)
        await Alert.send(firebase, {
            message: e.message,
            stack: e.stack,
            type: AlertType.INDEX_REQUIRED,
            entityType: AlertEntityType.PLATFORM
        })

        if (displayMessage) {
            const userMessage = i18n.t('temporary error, please retry in few hours')
            displayMessage(userMessage)
        }
    }

    static async userNotLogged(firebase:Firebase, e:Error, displayMessage?:displayMessage) {
        console.error(e)
        await Alert.send(firebase, {
            message: e.message,
            stack: e.stack,
            type: AlertType.USER_NOT_LOGGED,
            entityType: AlertEntityType.PLATFORM
        })

        if (displayMessage) {
            const userMessage = i18n.t('system error, system alert sent')
            displayMessage(userMessage)
        }
    }

    static async query(firebase:Firebase | null, e:Error, displayMessage?:displayMessage) {
        if (!firebase || e.message === 'no firebase') return ErrorManager.noFirebase(e, displayMessage)
        if (e.message === 'user not logged') return this.userNotLogged(firebase, e, displayMessage)
        if (e.message.indexOf('The query requires an index') !== -1)
            return this.indexRequired(firebase, e, displayMessage)

        console.error(e)
        await Alert.send(firebase, {
            message: e.message,
            stack: e.stack,
            type: AlertType.DB,
            entityType: AlertEntityType.PLATFORM
        })

        if (displayMessage) {
            const userMessage = i18n.t('error retrieving data, system alert sent')
            displayMessage(userMessage)
        }
    }

    static async fileNotFound(firebase:Firebase, path:string, displayMessage?:displayMessage) {
        await Alert.send(firebase, {
            message: path,
            type: AlertType.FILE_NOT_FOUND,
            entityType: AlertEntityType.PLATFORM
        })

        if (displayMessage) {
            const userMessage = i18n.t('file not found')
            displayMessage(userMessage)
        }
    }

    static async fileAccessDenied(firebase:Firebase, path:string, displayMessage?:displayMessage) {
        await Alert.send(firebase, {
            message: path,
            type: AlertType.FILE_ACCESS_DENIED,
            entityType: AlertEntityType.PLATFORM
        })

        if (displayMessage) {
            const userMessage = i18n.t('file access denied')
            displayMessage(userMessage)
        }
    }

    static async file(firebase:Firebase | null, e:Error, path:string, displayMessage?:displayMessage) {
        if (!firebase) return ErrorManager.noFirebase(e, displayMessage)
        console.error(e, e.message)

        if (e.message.indexOf('does not exist') !== -1) {
            await this.fileNotFound(firebase, path, displayMessage)
            return
        }

        if (e.message.indexOf('Firebase Storage: User does not have permission to access') !== -1) {
            const exists = await firebase.fileExists(path)
            if (exists.data) await this.fileAccessDenied(firebase, path, displayMessage)
                else await this.fileNotFound(firebase, path, displayMessage)
        } else {
            await Alert.send(firebase, {
                message: path,
                type: AlertType.STORAGE,
                entityType: AlertEntityType.PLATFORM
            })
            if (displayMessage) {
                const userMessage = i18n.t('storage error')
                displayMessage(userMessage)
            }
        }
    }

    static async fileElaboration(firebase:Firebase, path:string) {
        await Alert.send(firebase, {
            message: path,
            type: AlertType.STORAGE,
            entityType: AlertEntityType.PLATFORM
        })
    }

    static async procedure(firebase:Firebase | null, e:Error, procedureData:IProcedureEntry , displayMessage?:displayMessage) {
        if (!firebase) return ErrorManager.noFirebase(e, displayMessage)
        console.error(e, e.message)

        await Alert.send(firebase, {
            message: `${e.message} on procedure ${procedureData.type} (${ procedureData.id })`,
            stack: e.stack,
            type: AlertType.PROCEDURE,
            entityType: AlertEntityType.PLATFORM
        })
        if (displayMessage) {
            const userMessage = i18n.t('procedure error, system alert sent')
            displayMessage(userMessage)
        }
    }
}

export default ErrorManager