// logged items
export const DASHBOARD = '/dashboard'
export const DOCUMENTS = '/documents'
export const CONTACTS = '/contacts'
//export const GROUPS = '/groups'
//export const GROUP = '/group'
export const USERS = '/users'
export const USER = '/user'

// admin items
//export const GROUPADMINLIST = '/groupadminlist'

// anonymous items
export const LANDING = '/'
export const PRICING = '/pricing'
export const FEATURES = '/features'
export const DOCUMENT = '/document'
export const PRIVACY = '/privacy'

export function isLoggedLocation(location:string) {
    let result = true

    switch (location) {
        case LANDING:
        case PRICING:
        case PRIVACY:
            result = false
            break
    }

    return result
}