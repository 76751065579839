import React from 'react'
import { ListItem, ListItemAvatar, Avatar, ListItemText, makeStyles, List, Tooltip } from '@material-ui/core'
import { PersonalInfo } from '../Shared/User'


const useStyles = makeStyles(theme => ({
    item: {
      padding: 0
    }
  })
)

const PersonalInfoRow:React.SFC<{ user:PersonalInfo, short?:boolean }> = ({ user, short }) => {
    const classes = useStyles()
    if (!short) short = false

    return !short ? (

        <List dense classes={{ root: classes.item }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar src={ user.photoURL } alt={ user.name } />
                </ListItemAvatar>
                <ListItemText primary={ user.name } secondary={ user.email } />
            </ListItem>  
        </List>

    ) : (
        <Tooltip title={ `${user.name} (${user.email})` } aria-label={ `${user.name} (${user.email})` }>
            <Avatar src={ user.photoURL } alt={ user.name } />
        </Tooltip>
    )


    /*
    return ({
        short ? (


        
        ) : (<div />)*/
}

export default PersonalInfoRow