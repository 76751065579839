import React from "react"
import { Paper, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch } from "@material-ui/core"
import { useTranslation, Trans } from "react-i18next"
import { ProcedureType } from "../Shared/Procedure"
import { emailError } from "../Shared/Misc"

export interface RecipientRowData {
    recipientEmail: string
    saveContact: boolean
    procedureType?: ProcedureType
}

export interface RecipientRowProps {
    input: RecipientRowData
    showSaveContactControl: boolean
    onEmailChanged: (email:string) => void
    onProcedureTypeChanged: (value?:ProcedureType) => void
    onSaveContactChanged: (value:boolean) => void
}

const RecipientRow:React.SFC<RecipientRowProps> = ({input, showSaveContactControl, onEmailChanged, onProcedureTypeChanged, onSaveContactChanged}) => {
    const { t } = useTranslation()

    const recipientEmailError = emailError(input.recipientEmail, true, t)

    const saveContactControl = showSaveContactControl ? (
        <FormControlLabel
            style={{margin: ".5em 0"}}
            value="start"
            control={ <Switch 
                size="small" 
                color="primary"
                checked={ input.saveContact } 
                onChange={ (e) => onSaveContactChanged(!!e.target.checked) } 
            /> }
            label={ t('save contact') }
        />
    ) : null


    return (
        <Paper variant="outlined" square style={{padding: '1em'}}>
            <TextField
                style={{margin: ".5em 0"}}
                size="small"
                fullWidth
                error={ !!recipientEmailError }
                helperText={ recipientEmailError }
                label={ t('Email')}
                onChange={ (e) => onEmailChanged(e.target.value as string) }
                value={ input.recipientEmail }
                inputProps={{
                    type: 'email'
                }}
            />

            <FormControl fullWidth style={{margin: ".5em 0"}}>
                <InputLabel shrink><Trans>Notice Type</Trans></InputLabel>
                <Select
                    fullWidth
                    value={ input.procedureType ? input.procedureType : 'none' }
                    onChange={ (e) => onProcedureTypeChanged(e.target.value !== 'none' ? e.target.value as ProcedureType : undefined) }
                >
                    <MenuItem value="none"><Trans>none</Trans></MenuItem>
                    <MenuItem value={ ProcedureType.NOTIFICATION_DOCUMENT_UPLOADED }><Trans>notification</Trans></MenuItem>
                    <MenuItem value={ ProcedureType.ACKNOWLEDGEMENT }><Trans>acknowledgement</Trans></MenuItem>
                </Select>
            </FormControl>

            { saveContactControl }
        </Paper>
    )
}

export default RecipientRow