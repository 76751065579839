import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from "html-to-draftjs"
import React from "react"
import { Editor } from "react-draft-wysiwyg"
import { Trans, useTranslation } from "react-i18next"
import i18n from '../i18n'
import Template from "../Models/Template"

export interface TemplateEditDialogProps {
    title: string,
    template: Template,
    handleCloseWithoutSaving: () => void,
    submit: (subject:string, html:string) => void
}

const TemplateEdit:React.SFC<TemplateEditDialogProps> = ( {title, template, handleCloseWithoutSaving, submit} ) => {
    let initialEditorState:EditorState
    const { t } = useTranslation()

    if (template.html) {
        const contentBlock = htmlToDraft(template.html)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        initialEditorState = EditorState.createWithContent(contentState)
    } else initialEditorState = EditorState.createEmpty()
    
    const [status, setStatus] = React.useState({
        subject: template.subject ? template.subject : '',
        editorState: initialEditorState,
    })

    const toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'list', 'textAlign', 'link', 'emoji', 'image', 'remove', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline']
        },
        colorPicker: {
            colors: ['rgb(0,96,100)', 'rgb(255,80,57)', 'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
      'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
      'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
      'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
      'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
      'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)']
        }
    }

    function onSubmit() {
        const raw = convertToRaw( status.editorState.getCurrentContent() )
        const html = draftToHtml(raw)
        submit(status.subject, html)
    }

    function onEditorStateChange(newEditorState:EditorState) {
        setStatus({
            ...status,
            editorState: newEditorState
        })
    }

    function onSubjectChange(e:any) {
        setStatus({
            ...status,
            subject: e.target.value
        })
    }

    return (
        <Dialog 
            open
            onClose={ handleCloseWithoutSaving }
            aria-labelledby="template-edit-dialog-title"
            scroll="paper"
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle id="template-edit-dialog-title">{ title }
            <Typography variant="body2"><i><b><Trans>Available tokens</Trans></b>: { template.getTokens().join(' - ') }</i></Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container flex-direction="column" spacing={ 1 }>
                    <Grid item xs={12}>
                        <TextField
                            id="subject"
                            fullWidth
                            required
                            label={ t('Subject') }
                            value={ status.subject }
                            onChange={ onSubjectChange }
                        />
                    </Grid>
                    <Grid item>
                        <Box p={1} 
                            border={1}
                            borderColor="secondary.main"
                            borderRadius="borderRadius"
                        >
                            <Editor 
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange = { onEditorStateChange }
                                editorState = { status.editorState }
                                toolbar = { toolbar }
                                localization={{locale: i18n.language}}
                                editorStyle={{minHeight: '15em'}}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseWithoutSaving } color="secondary">
                    <Trans>Cancel</Trans>
                </Button>
                <Button onClick={ onSubmit } color="primary">
                    <Trans>Submit</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateEdit