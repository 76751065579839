import React from "react"
import Layout, { LayoutProps } from '../Components/Layout'
import { Trans } from "react-i18next"

export interface MessageWithLayoutProps {
    message: string,
    layoutProps?: LayoutProps
}

const MessageWithLayout = ( props: MessageWithLayoutProps ) => {

    return (
        <Layout{ ...props.layoutProps }>
            <div>{ props.message }</div>
        </Layout>
        
    )
}

const AccessDenied:React.SFC<LayoutProps | {}> = ( props ) => {
    return (
        <Layout{ ...props }>
            <div><Trans>Access Denied</Trans></div>
        </Layout>
    )
}

const PageNotFound:React.SFC<LayoutProps | {}> = ( props ) => {
    return (
        <Layout{ ...props }>
            <div><Trans>Page Not Found</Trans></div>
        </Layout>
    )
}

export { AccessDenied, PageNotFound }
export default MessageWithLayout