import React from "react"
import { Typography, Box } from "@material-ui/core"

export interface TabPanelProps {
    children?: React.ReactNode
    ariaLabelledby: string
    id: string
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, id, ariaLabelledby, value, index, ...other } = props
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={ id }
            aria-labelledby={ ariaLabelledby }
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    )
}

export default TabPanel