import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction } from "@material-ui/core"
import ClearIcon from '@material-ui/icons/Clear'
import React from "react"
import { useTranslation } from "react-i18next"
import Alert from "../Models/Alert"
import ErrorManager from "../Models/ErrorManager"
import { FirebaseContext } from "../Models/Firebase"
import { DataStatus } from "../Shared/Misc"

const Alerts = function() {
    const { t } = useTranslation()
    const firebase = React.useContext(FirebaseContext)
    const [data, setData] = React.useState({
        message: '',
        list: [] as Alert[],
        status: DataStatus.LOADING
    })
    
    async function loader() {
        try {
            if (!firebase) throw new Error('no firebase')
            const alerts = firebase.db.collection('alerts')
            const snap = await alerts.orderBy('timestamp', 'desc').get()
            if (!snap.empty) {
                const list = snap.docs.map( el => Alert.fromSnap(el) )
                setData({
                    ...data,
                    status: DataStatus.READY,
                    list: list
                })
            } else {
                setData({
                    ...data,
                    list: [],
                    status: DataStatus.EMPTY,
                    message: t('no alerts')
                })
            }
        } catch (e) {
            ErrorManager.query(firebase, e, (msg) => setData({
                ...data,
                status: DataStatus.EMPTY,
                message: msg
            }))
        }
    }

    async function dismissAlert(alert:Alert) {
        try {
            if (!firebase) throw new Error('no firebase')
            const alerts = firebase.db.collection('alerts')
            await alerts.doc(alert.id).delete()
            for (let pos = 0; pos < data.list.length; ++pos) {
                if (data.list[pos].id === alert.id) {
                    data.list.splice(pos, 1)
                    break
                }
            }
            setData({
                ...data,
                list: data.list
            })
        } catch (e) {
            ErrorManager.query(firebase, e, (msg) => setData({
                ...data,
                list: [],
                status: DataStatus.EMPTY,
                message: msg
            }))
        }
    }

    if (data.message) return (<div>{ data.message }</div>)
    if (data.status === DataStatus.LOADING) loader()

    const elements = data.list.map( alert => (
        <ListItem key={ alert.id }>
            <ListItemAvatar>
                <Avatar>
                    { alert.getIcon() }
                </Avatar>
            </ListItemAvatar>
            { alert.getItemText() }
            <ListItemSecondaryAction>
                <IconButton onClick={ () => dismissAlert(alert) } edge="end" aria-label="dismiss">
                    <ClearIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    ))

    return (
        <List dense>
            { elements }
        </List>
    )
}

export default Alerts