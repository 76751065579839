import { Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { LayoutLoginCtrl } from '../Components/Layout'
import { DASHBOARD } from "../Constants/Routes"
import DocumentAcknowledgementDialog from '../Dialogs/DocumentAcknowledgement'
import Doc from "../Models/Doc"
import ErrorManager from "../Models/ErrorManager"
import { FirebaseApp, FirebaseContext } from "../Models/Firebase"
import Procedure from "../Models/Procedure"
import { UserContext, UserStatus } from "../Models/User"
import { DocStatus } from "../Shared/Doc"
import { DataStatus } from '../Shared/Misc'
import { ProcedureActionType, ProcedureStatus, ProcedureType } from "../Shared/Procedure"
import { PageNotFound } from "./MessageWithLayout"

const DocumentPage = () => {
    const firebase = React.useContext(FirebaseContext)
    const dialogAcknowledgementRef = React.useRef()
    const layoutRef = React.useRef()
    const currentUser = React.useContext(UserContext).currentUser
    const { t } = useTranslation()
    const history = useHistory()
    const [ data, setData ] = React.useState({
        status: DataStatus.LOADING,
        doc: null as Doc | null,
        procedure: null as Procedure | null,
        message: ''
    })

    const location = useLocation()
    const locationPieces = location.pathname.split('/')

    async function retrieveData(id: string) {
        try {
            if (!firebase) throw new Error('no firebase')
            const documents = firebase.db.collection('documents')
            const procedures = firebase.db.collection('procedures')
            const snap = await documents.doc(id).get()
            let message = ''
            let procedure = null as Procedure | null

            if (!snap.exists) {
                setData({
                    ...data,
                    status: DataStatus.EMPTY
                })
                return
            }
            const doc = Doc.fromSnap(snap as FirebaseApp.firestore.QueryDocumentSnapshot<FirebaseApp.firestore.DocumentData>)

            if (currentUser.getStatus() === UserStatus.LOGGED && doc.status === DocStatus.WAITING_RESPONSE) {
                const snapProcedure = await procedures.where('handler', '==', doc.id).where('recipientEmail', '==', currentUser.email).limit(1).get()
                if (!snapProcedure.empty) procedure = Procedure.fromSnap(snapProcedure)
            } else {
                message = t('Please, login to open this document')
            }

            setData({
                ...data,
                doc: doc,
                status: DataStatus.READY,
                procedure: procedure,
                message: message
            })
        } catch (e) {
            //This document is reserved to a user with a different email. If you received an email about this document please check the email recipient on that.
            console.error(e)
        }
    }
    
    async function acknowledged(doc:Doc, procedure:Procedure) {
        try {
            if (!firebase) throw new Error('no firebase')
            await procedure.sendAction(firebase, { type: ProcedureActionType.ACKNOWLEDGED })
            await openDocAndGoToDashboard(doc)
        } catch (e) {
            ErrorManager.procedure(firebase, e, procedure.getData(), (err) => {
                setData({
                    ...data,
                    message: err
                })
            })
        }
    }

    async function openDocAndGoToDashboard(doc:Doc) {
        const success = await doc.open(firebase, (err) => {
            setData({
                ...data,
                message: err
            })
        })
        if (success) history.push(DASHBOARD)
    }

    async function docActionsForLoggedUser() {
        if (!data.doc) throw new Error('doc should not be empty')
        if (data.procedure) {
            const procedureData = data.procedure.getData()
            switch (procedureData.type) {
                case ProcedureType.ACKNOWLEDGEMENT:
                    if (procedureData.status === ProcedureStatus.ACTION_REQUIRED) {
                        // @ts-ignore
                        dialogAcknowledgementRef.current.open(data.doc, procedure)
                        return
                    }
            }
        }
        openDocAndGoToDashboard(data.doc)
    }

    React.useEffect( () => {
        if (!firebase || currentUser.getStatus() === UserStatus.UNKNOWN) return
        switch (data.status) {
            case DataStatus.READY:
                switch (currentUser.getStatus()) {
                    case UserStatus.LOGGED:
                        docActionsForLoggedUser()
                        break
                    case UserStatus.ANONYMOUS:
                        // @ts-ignore
                        layoutRef.current.handleLoginDialogOpening(true)
                        break
                }
                break
            case DataStatus.LOADING:
                retrieveData(id)
                break
        }
    })

    if (locationPieces.length !== 3) return (<PageNotFound />)
    const id = locationPieces[2]

    if (!firebase || currentUser.getStatus() === UserStatus.UNKNOWN) return null
    if (data.status === DataStatus.EMPTY) return ( <PageNotFound /> )

    return (
        <LayoutLoginCtrl
            ref={ layoutRef } 
            title={ t('Document') }
        >
            <DocumentAcknowledgementDialog
                ref={ dialogAcknowledgementRef }
                handleCloseWithoutSaving = { () => history.push(DASHBOARD) }
                handleAccepted = { acknowledged }
            />
            { data.message ? (<Typography>{ data.message }</Typography>) : null }
        </LayoutLoginCtrl>
    ) 
}

export default DocumentPage