import { defaultLanguage } from "./Constants"

export interface PersonalInfo {
    name: string,
    email: string,
    photoURL: string
}

export interface IUserDb {
    id?: string
    email: string,
    displayName?: string,
    photoURL?: string,
    language?: string,
    deleted?: boolean,
    privacyPolicySigned?: boolean
}

export interface IUserFirebaseAuth {
    id?: string,
    email: string,
    displayName?: string,
    photoURL?: string
}

export interface IUserAuth extends IUserFirebaseAuth {
    isAdmin?: boolean
}

export interface IUser extends IUserDb, IUserAuth {
}

export interface CustomClaims {
    admin: boolean
}

class AbsUser implements IUser {
    id?: string
    email: string
    displayName?: string
    photoURL?: string
    isAdmin = false
    language = defaultLanguage
    deleted = false
    privacyPolicySigned = false

    constructor(data:IUser) {
        this.id = data.id
        this.email = data.email
        this.displayName = data.displayName
        this.photoURL = data.photoURL
        if (data.isAdmin !== undefined) this.isAdmin = data.isAdmin
        if (data.language) this.language = data.language
        if (data.deleted !== undefined) this.deleted = data.deleted
        if (data.privacyPolicySigned !== undefined) this.privacyPolicySigned = data.privacyPolicySigned
    }

    getData() {
        const data = {
            id: this.id,
            email: this.email,
            displayName: this.displayName,
            photoURL: this.photoURL,
            isAdmin: this.isAdmin,
            language: this.language,
            deleted: this.deleted,
            privacyPolicySigned: this.privacyPolicySigned,
        } as IUser

        return data
    }

    getSnapData() {
        const data = {
            email: this.email,
            language: this.language,
            deleted: this.deleted,
            privacyPolicySigned: this.privacyPolicySigned,
        } as IUserDb

        if (this.displayName) data.displayName = this.displayName
        if (this.photoURL) data.photoURL = this.photoURL

        return data
    }

    getCustomClaims() {
        return {
            admin: this.isAdmin
        } as CustomClaims
    }

    updateByCustomClaims(customClaims:CustomClaims) {
        this.isAdmin = customClaims.admin
    }

    updateByFirebaseAuth(data:IUserFirebaseAuth) {
        let somethingDone = false

        if (data.email !== this.email) throw new Error('system should not change email like this')
        if (data.displayName !== this.displayName) somethingDone = true
        if (data.photoURL !== this.photoURL) somethingDone = true

        return somethingDone
    }

    static getClaimsFromData(data:any):CustomClaims {
        return {
            admin: data.admin
        }
    }

    static defaultCustomClaims():CustomClaims {
        return {
            admin: false
        }
    }
}

export default AbsUser