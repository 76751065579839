import React, { useRef } from "react"
import { LayoutLoginCtrl } from '../Components/Layout'
import { Typography, Grid, Theme, makeStyles, createStyles, Divider, Button, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { Trans } from "react-i18next"
import StorageIcon from '@material-ui/icons/Storage'
import MailIcon from '@material-ui/icons/Mail'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import './Landing.css'

const ScissorsIcon = createSvgIcon(
    <React.Fragment>
        <path fill='none' d='M0 0h24v24H0z' />
        <path fill="color" stroke="color" strokeWidth="1" d="M 2.863281 4.628906 C 1.15625 5.078125 0.0351562 6.589844 0.277344 8.136719 C 0.46875 9.394531 1.496094 10.464844 2.851562 10.816406 C 3.386719 10.957031 4.300781 10.964844 4.832031 10.824219 C 5.320312 10.703125 6.011719 10.347656 6.363281 10.039062 C 6.503906 9.917969 6.640625 9.824219 6.652344 9.824219 C 6.671875 9.824219 7.269531 10.167969 7.980469 10.597656 L 9.269531 11.375 L 9.890625 10.972656 C 10.230469 10.753906 10.554688 10.535156 10.597656 10.496094 C 10.65625 10.445312 10.25 10.246094 8.941406 9.695312 C 7.582031 9.117188 7.21875 8.9375 7.25 8.859375 C 7.359375 8.578125 7.46875 8.027344 7.46875 7.757812 C 7.46875 5.621094 5.132812 4.03125 2.863281 4.628906 Z M 5.042969 5.761719 C 5.621094 6.03125 6.058594 6.457031 6.28125 6.949219 C 6.433594 7.296875 6.453125 7.398438 6.429688 7.828125 C 6.410156 8.160156 6.351562 8.40625 6.253906 8.589844 C 5.949219 9.195312 5.210938 9.71875 4.464844 9.878906 C 3.246094 10.136719 1.984375 9.59375 1.453125 8.570312 C 1.222656 8.140625 1.207031 7.378906 1.417969 6.921875 C 1.683594 6.347656 2.421875 5.757812 3.085938 5.589844 C 3.222656 5.558594 3.621094 5.539062 3.972656 5.542969 C 4.542969 5.558594 4.664062 5.582031 5.042969 5.761719 Z M 5.042969 5.761719 "/>
        <path fill="color" stroke="color" strokeWidth="1" d="M 20.488281 7.03125 C 17 7.96875 13.734375 9.304688 11.09375 10.875 C 10.636719 11.152344 9.4375 11.945312 8.429688 12.640625 C 7.421875 13.339844 6.570312 13.929688 6.523438 13.953125 C 6.480469 13.980469 6.28125 13.882812 6.070312 13.734375 C 3.804688 12.195312 0.566406 13.445312 0.257812 15.992188 C 0.0742188 17.519531 1.308594 19.054688 3.011719 19.414062 C 5.390625 19.914062 7.648438 18.179688 7.449219 16.007812 C 7.417969 15.671875 7.289062 15.261719 7.042969 14.730469 C 7.03125 14.691406 10.785156 13.0625 15.390625 11.105469 C 20.410156 8.96875 23.757812 7.515625 23.757812 7.460938 C 23.757812 7.316406 23.226562 7.136719 22.375 7 C 21.917969 6.929688 21.488281 6.859375 21.417969 6.847656 C 21.347656 6.835938 20.929688 6.917969 20.488281 7.03125 Z M 4.464844 14.125 C 6.359375 14.523438 7.089844 16.558594 5.773438 17.765625 C 5.289062 18.210938 4.75 18.429688 4.035156 18.46875 C 3.042969 18.519531 2.226562 18.1875 1.6875 17.5 C 0.386719 15.859375 2.203125 13.652344 4.464844 14.125 Z M 4.464844 14.125 "/>
        <path fill="color" stroke="color" strokeWidth="1" d="M 13.175781 12.550781 C 12.492188 12.851562 11.902344 13.101562 11.863281 13.113281 C 11.773438 13.144531 12.867188 13.691406 14.042969 14.203125 C 15.753906 14.941406 17.757812 15.628906 19.769531 16.167969 L 20.488281 16.359375 L 21.503906 16.199219 C 22.496094 16.046875 22.957031 15.890625 22.957031 15.71875 C 22.957031 15.667969 21.394531 14.96875 18.691406 13.820312 C 16.351562 12.820312 14.425781 12.003906 14.425781 12.011719 C 14.425781 12.011719 13.863281 12.253906 13.175781 12.550781 Z M 13.175781 12.550781 "/>
    </React.Fragment>,
    'ScissorsIcon'
  )

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgScreens: {
        maxWidth: '100%',
        boxShadow: '0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15)'
    },
    paragraph: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    }

  }),
)

const Landing = () => {
    const classes = useStyles()
    const layoutRef = useRef()

    function openLoginDialog() {
        //@ts-ignore
        layoutRef.current.handleLoginDialogOpening(true)
    }

    return (
        <LayoutLoginCtrl ref={ layoutRef }>
            <Grid container spacing={ 4 } alignContent="space-between">
                <Grid item md={6} xs={12}>
                    <img alt="screenshots of the app" src="img/landing-screens.jpg" className={ classes.imgScreens } />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography className={classes.title} variant="h3"><Trans>Payload - Document Management</Trans></Typography>
                    <Typography className={classes.paragraph} variant="body1"><Trans><b>Payload</b> is a Software as a Service created to archive documents like <b>unique certifications</b> and <b>paychecks</b>.</Trans></Typography>
                    <Divider />
                    <Typography className={classes.paragraph} variant="body2"><Trans>It provides also a seamless way to send your documents and ask for an <b>acknowledgement</b> or a <b>countersignature</b>.</Trans></Typography>
                    <Grid spacing={ 2 } container>
                        <Grid item><Button
                            variant="contained"
                            color="primary"
                            onClick={ openLoginDialog }
                        ><Trans>Enter for free</Trans></Button></Grid>
                        <Grid item><Button
                            variant="contained"
                            color="primary"        
                        ><Trans>Discover more</Trans></Button></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}><Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center"><Trans>About Payload</Trans></Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <List>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon>
                            <ScissorsIcon color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    component="div"
                                    variant="h4"
                                >
                                    <Trans>Multiple Documents Detection</Trans>
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                >
                                    <Trans>Do you have <b>all paychecks in a single PDF</b>? No problem: just upload your file and <b>Payload</b> will split the documents and will send a notification to right recipient for you!</Trans>
                                </Typography>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon>
                            <MailIcon color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography
                                    component="div"
                                    variant="h4"
                                >
                                    <Trans>Email notifications</Trans>
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                >
                                    <Trans>As soon as you uploaded your document you can alert by email your recipient the new document is available. You ask him also for an <b>acknowledgement</b> or a <b>countersignature</b>.</Trans>
                                </Typography>
                            }
                        />
                    </ListItem>

                        <ListItem alignItems="flex-start">
                            <ListItemIcon>
                                <StorageIcon color="primary" fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="div"
                                        variant="h4"
                                    >
                                        <Trans>Cloud Storage</Trans>
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        component="span"
                                        variant="body2"
                                    >
                                        <Trans>Take advantage of the cloud <b>flexibility</b> and <b>scalability</b>. Your documents will be always available for consultation.</Trans>
                                    </Typography>
                                }
                            />
                        </ListItem>

                    </List>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography color="primary" variant="h4" align="center" className="title"><Trans>Watch our "5 min Quick Start" tutorial</Trans></Typography>
                    <div className="landingVideo">
                        <iframe 
                            title="video tutorial"
                            src="https://www.youtube.com/embed/CQBQx-n6qG4"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
                        </iframe>
                    </div>
                </Grid>
            </Grid>

                
                
        </LayoutLoginCtrl>
        
    )
}

export default Landing