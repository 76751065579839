import React, { useContext } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { FirebaseContext, FirebaseApp } from '../Models/Firebase'
import { Dialog, DialogTitle, Backdrop, CircularProgress } from '@material-ui/core'
import { Trans } from 'react-i18next'
import ErrorManager, { NO_FIREBASE } from '../Models/ErrorManager'
import ErrorSnackbar from '../Components/ErrorSnackbar'

const Login:React.SFC<{opened: boolean, onClose: any}> = ({ opened, onClose }) => {
    const firebase = useContext(FirebaseContext)
    const errorSnackbarRef = React.useRef<typeof ErrorSnackbar>(null)
    const [status, setStatus] = React.useState({ loading: false })
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            FirebaseApp.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        privacyPolicyUrl: 'https://payload.wink.by/privacy',
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => {
                addLoginEventInAnalytics()
                return false
            }
        }
    }

    async function addLoginEventInAnalytics() {
        try {
            setStatus({ ...status, loading: true })
            if (!firebase) throw new Error(NO_FIREBASE)
            const currentUser = firebase.app.auth().currentUser
            if (!currentUser) throw new Error('no current user')
            else firebase.analytics.logEvent('login', {method: currentUser.providerId})
        } catch (e) {
            //@ts-ignore
            ErrorManager.query(firebase, e, errorSnackbarRef.current.open)
        }
    }

    if (!firebase) {
        console.warn(NO_FIREBASE)
        return null
    }

    return (
        <React.Fragment>
            <ErrorSnackbar ref={ errorSnackbarRef } />

            <Dialog open={ opened && status.loading }>
                <Backdrop open={ status.loading }>
                    <CircularProgress color="secondary" />
                </Backdrop>
            </Dialog>
            
            <Dialog aria-labelledby="login-dialog" open={ opened && !status.loading } onClose={ onClose } >
                <DialogTitle><Trans>Login</Trans></DialogTitle>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.app.auth()} />
            </Dialog>
        </React.Fragment>
    )
}

export default Login