import Firebase from "./Firebase"

class CommonFirebase {
    static async save(firebase: Firebase, obj:any, collectionName:string) {
        const data = obj.getSnapData()
        const collection = firebase.db.collection(collectionName)

        if (!obj.id) {
            const ref = await collection.add(data)
            obj.id = ref.id
        } else await collection.doc(obj.id).set(data)
    }

    static async delete(firebase: Firebase, obj:any, collectionName:string) {
        const collection = firebase.db.collection(collectionName)
        if (!obj.id) throw new Error(`no id to delete in collection "${collectionName}"`)
        await collection.doc(obj.id).delete()
    }
}

export default CommonFirebase