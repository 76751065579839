import AbsContact, { IContact } from "../Shared/Contact";
import Firebase, { FirebaseApp } from "./Firebase";
import CommonFirebase from "./CommonFirebase";
import { GetByOptions } from "./User";
import _ from "lodash";

type DocumentSnapshot = FirebaseApp.firestore.DocumentSnapshot<FirebaseApp.firestore.DocumentData>

class Contact extends AbsContact {
    async save(firebase: Firebase) {
        await CommonFirebase.save(firebase, this, 'contacts')
    }

    async updateEmailAndRelatedDocs() {

    }

    static async getById(firebase:Firebase, id:string, inputOptions?:GetByOptions) {
        let data = [] as IContact[]
        const options = _.defaults(inputOptions, {
            saveInSessionStorage: true,
            forceFromNetwork: false
        })

        if (!options.forceFromNetwork) {
            let contactsStoredStr = window.sessionStorage.getItem('contacts')
            if (contactsStoredStr) {
                data = JSON.parse(contactsStoredStr)
                for (let i in data) if (data[i].id === id) return new Contact(data[i])
            }
        }

        const collection = firebase.db.collection('contacts')
        try {
            const snap = await collection.doc(id).get()
            if (!snap.exists) return null
            const contact = this.fromSnap(snap)
            if (options.saveInSessionStorage) {
                data.push(contact.getData())
                window.sessionStorage.setItem('contacts', JSON.stringify(data))
            }
            return contact
        } catch (e) {
            if (e.message.indexOf('Missing or insufficient permissions') !== -1) return null
                else throw e
        }
    }

    static async upsertContact(firebase:Firebase, contact:Contact) {
        const contacts = firebase.db.collection('contacts')
        let data = contact.getSnapData()
        let id = contact.id

        if (!id) {
            let snap = await contacts
                .where('owner', '==', contact.owner)
                .where('email', '==', contact.email)
                .limit(1)
                .get()
        
            if (snap.empty && contact.group) {
                snap = await contacts
                    .where('group', '==', contact.group)
                    .where('email', '==', contact.email)
                    .limit(1)
                    .get()
            }

            if (!snap.empty) id = snap.docs[0].id as string
        }

        if (id) {
            delete data.owner
            await contacts.doc(id).set(data, {merge : true})
        }
            else await contacts.add(data)
    }

    static fromSnap(snap:DocumentSnapshot) {
        let contact = new Contact(snap.data() as IContact)
        contact.id = snap.id
        
        return contact
    }
}

export default Contact